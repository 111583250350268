import axios from "axios";
import React, { useEffect, useState } from "react";
import { getHeader } from "../../../service/auth";
import Paper from "@mui/material/Paper";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { motion } from 'framer-motion';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { StyledTableRow } from "../../../utils/styledTable";
import { useNavigate } from "react-router-dom";
import {
  DatasetLinked,
  DeleteForever,
  Edit,
  List,
} from "@mui/icons-material";
import SnackAlert from "../../../components/SnackAlert";
import themeNutriFast from "../../../styles/NutriFastColors";


const BtnAnnuaire = {
  /* color: "grey",*/
  display: "contents",
  float: "left",
};

const padTr = {
  padding: "6px",
};

const pAnuaireBtn = {
  color: "grey",
  display: "contents",
  float: "left",
};

const GestionUsers = () => {
  const [users, setUsers] = useState([]);
  const headers = getHeader();
  let navigate = useNavigate();

  const [alert, setAlert] = useState({
    message: "",
    severity: "info",
    open: false,
  });
  

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlert({ message: "", severity: "info", open: false });
  };

  useEffect(() => {
    axios.get(process.env.REACT_APP_ADMIN, { headers }).then((response) => {
      setUsers(response.data);
    });
  }, []);

  const handleEdit = (index) => {
    navigate("/admin/user/edit", { state: users[index] });
  };

  const handleShow = (index) => {
    navigate("/admin/user/show", { state: users[index] });
  };

  const handleDelete = (index) => {
    let user = users[index];

    axios
      .delete(process.env.REACT_APP_ADMIN + "/user/" + user.id, { headers })
      .then(() => {
        setAlert({
          message: "Utilisateur supprimé",
          severity: "info",
          open: true,
        });
        users.splice(index, index);
      })
      .catch((err) =>
        setAlert({
          message: "Une erreur s'est produite",
          severity: "error",
          open: true,
        })
      );
  };

  const handleAssoc = (index) => {
    navigate("/admin/assoc_quest", { state: users[index] });
  };

  return (
    <motion.div initial={{opacity:0}} animate={{opacity:1}} transition={{ duration: 2 }}>
    <Paper style={{ padding: 30 }}>

      <TableContainer component={Paper}>
        {users && (
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell style={padTr}>
                  <p style={BtnAnnuaire}>Courriel</p>
                </TableCell>
                <TableCell style={padTr}>
                  <p style={{display:"contents"}}>Username</p>
                </TableCell>
                <TableCell style={padTr}>
                  <p style={BtnAnnuaire}>Date de naissance</p>
                </TableCell>
                <TableCell style={padTr}>
                  <p style={BtnAnnuaire}>Actions</p>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((row, index) => (
                
                <StyledTableRow
                  key={"list" + index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  
                  <TableCell>{row.mail}</TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.birth_date}</TableCell>
                  <TableCell>
                    <Button 
                      style={pAnuaireBtn }
                      title="Editer ce volontaire"
                      onClick={() => handleEdit(index)}
                    >
                     
                      <Edit  style={{color:themeNutriFast.palette.primary.dark}} className="tablecelladd"/>
                    </Button>
                    <Button
                      style={pAnuaireBtn}
                      title="Voir le(s) questionnaire(s)"
                      onClick={() => handleShow(index)}
                    >
                      
                      <List  className="tablecelladd" style={{color:themeNutriFast.palette.primary.dark}}/>
                    </Button>
                    <Button
                      style={pAnuaireBtn}
                      title="Associer un questionnaire"
                      onClick={() => handleAssoc(index)}
                    >
                     
                      <DatasetLinked className="tablecelladd" style={{color:themeNutriFast.palette.primary.dark}}/>
                    </Button>                    
                    <Button
                      style={pAnuaireBtn}
                      title="Supprimmer ce volontaire"
                      onClick={() => handleDelete(index)}
                    >
                      <DeleteForever className="tablecelladd" style={{color:themeNutriFast.palette.primary.dark}}/>
                    </Button>
                  </TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>

      {users.length === 0 && (
        <div
          style={{
            background: "red",
            padding: "5px",
            fontWeight: "bold",
          }}
        >
          Pas de resultat
        </div>
      )}



   
       
       

    
        
   
<div className="fab-container">
    <span>Ajouter un volontaire</span> 
    <div style={{ backgroundColor: themeNutriFast.palette.primary.dark }}
      className="button-add iconbutton" 
      title="Ajouter un volontaire"
      onClick={() => {
          navigate("/admin/user/add");
        }}>
      <span>
        <AddOutlinedIcon>
         </AddOutlinedIcon>
       </span> 
  </div>
</div>
    
      <SnackAlert
        open={alert.open}
        severity={alert.severity}
        message={alert.message}
        handleClose={handleClose}
      />
    </Paper>
    </motion.div>
  );
};

export default GestionUsers;

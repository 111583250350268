import React, { useEffect, useState } from "react";
import { getHeader } from "../../../service/auth";
import { useLocation, useNavigate } from "react-router-dom";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import axios from "axios";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import SnackAlert from "../../../components/SnackAlert";
import { StyledTableRow } from "../../../utils/styledTable";
import { AutoFixHigh, Delete, Edit, SwapVert } from "@mui/icons-material";
import { useSnackContext } from "../../../components/SnackContext";
import AddPage from "./AddPage";
import EditPage from "./EditPage";
import themeNutriFast from "../../../styles/NutriFastColors";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";

const ShowQuestionnaire = () => {
  const headers = getHeader();
  const { state } = useLocation();
  const [list, setList] = useState([]);
  const { alert, handleClose, setAlert } = useSnackContext();
  const [open, setOpen] = useState(false);
  let navigate = useNavigate();
  const [defaultValues, setValues] = useState({ title: '', questionnaire_id: '', id: '' })
  const [openEdit, setOpenEdit] = useState(false)

  useEffect(() => {
    if(state){
      axios
      .get(process.env.REACT_APP_ADMIN + "/form/all/" + state, {
        headers,
      })
      .then(({ data }) => {
        console.log(data)
        setList(data);
      });
    }
    else{
      navigate("/admin/gestion_questionnaire");
    }
  }, []);

  const editName = (title, id) => {
    setValues({ id: id, questionnaire_id: state, title: title })
    setOpenEdit(true)
  }

  const handleDelete = async (id) => {
    await axios
      .delete(process.env.REACT_APP_ADMIN + "/form/" + id, { headers })
      .then(() => {

        let newdata = list.filter(function(el) { return el.id !== id; });

        setList(newdata)
        setAlert({
          message: "Page supprimé",
          severity: "success",
          open: true,
        });
      })
      .catch(
        setAlert({
          message: "Une erreur s'est produite",
          severity: "error",
          open: true,
        })
      );
  };

  const handleDragEnd = async (e) => {
    if (!e.destination) return;
    
    let tempData = Array.from(list);
    
    
    let [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data);
    
    let newPosition = tempData.map((page,index)=>{
      
      page.page = index+1
      return page
    })
    await axios
      .post(process.env.REACT_APP_ADMIN + "/form/update/position", { newPosition },{ headers })
      .then(() => {

        setList(newPosition)
        setAlert({
          message: "Position mise à jour",
          severity: "success",
          open: true,
        });
      })
      .catch(
        setAlert({
          message: "Une erreur s'est produite",
          severity: "error",
          open: true,
        })
      );
    
    
  };
  return (
    <Paper style={{ padding: 30 }}>
      <TableContainer component={Paper}>
        <DragDropContext onDragEnd={handleDragEnd}>
        {list && (
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  
                </TableCell>
                <TableCell>
                  <p>Titre</p>
                </TableCell>
                <TableCell>
                  <p>Actions</p>
                </TableCell>
              </TableRow>
            </TableHead>
            <Droppable droppableId="quest-1">
            {(provider) => (
              
            <TableBody ref={provider.innerRef} {...provider.droppableProps} >
              {list.map((row, index) => (
                <Draggable key={"list"+row.id} draggableId={"list"+row.id} index={index}>
                  {(provider) => (
                <StyledTableRow
                  key={"list" + index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  {...provider.draggableProps}
                  ref={provider.innerRef}
                >
                  <TableCell 
                  component="th" 
                  scope="row"
                  {...provider.dragHandleProps}
                  >
                    <SwapVert />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.title}
                  </TableCell>
                  <TableCell>
                    <Button
                      title="Editer la page"
                      onClick={() =>
                        navigate("/admin/questionnaire/edit", { state: { id: row.id, quest_id: state } })
                      }
                    >
                      <AutoFixHigh />
                    </Button>
                    <Button
                      title="Editer le nom"
                      onClick={() => editName(row.title, row.id)}
                    >
                      <Edit />
                    </Button>

                    <Button

                      title="Supprimer le questionnaire"
                      onClick={() =>
                        handleDelete(row.id)
                      }
                    >
                      <Delete
                        className="tablecelladd"
                        style={{ color: themeNutriFast.palette.primary.dark }}
                      />
                    </Button>
                  </TableCell>
                </StyledTableRow>
                  )}
                  </Draggable>
              ))}
              {provider.placeholder}
            </TableBody>
            )}
            </Droppable>
          </Table>
        )}
        </DragDropContext>
      </TableContainer>


      <div className="fab-container">
        <span> Ajouter une page</span>
        <div style={{ backgroundColor: themeNutriFast.palette.primary.dark }}
          className="button-add iconbutton"
          title=" Ajouter une page"
          onClick={() => {
            // navigate("/admin//questionnaire/add_questionnaire");
            setOpen(true)
          }}
        >
          <span>
            <AddOutlinedIcon>
            </AddOutlinedIcon>
          </span>
        </div>
      </div>




      <AddPage
        openDialog={open}
        setOpenDialog={setOpen}
        setPage={setList}
        page={list.length + 1}
        quest_id={state}
      />
      <EditPage openDialog={openEdit} setOpenDialog={setOpenEdit} setPage={setList} defaultValues={defaultValues} />
      <SnackAlert
        open={alert.open}
        severity={alert.severity}
        message={alert.message}
        handleClose={handleClose}
      />

      {/* <Button onClick={onSubmit}> Ajouter une page</Button> */}
    </Paper>
  );
};

export default ShowQuestionnaire;

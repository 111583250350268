import { Alert, AlertTitle, Box, Grid } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { isLogged, saveToken } from "../service/auth";
import { useLocation, useNavigate } from "react-router-dom";
import InfoAlert from "../components/InfoAlert";
import themeNutriFast from "../styles/NutriFastColors";
import FormDatePicker from "../components/FormDatePicker";
import { useForm } from "react-hook-form";

export default function Forget(props) {
  
  const [errorMessages, setErrorMessages] = useState({});
  const [error, setError] = useState(false);
  // const [showMessage, setShowMessage] = useState(true);
  // let { state } = useLocation();

  let navigate = useNavigate();

  useEffect(() => {
    if (isLogged()) {
      navigate("/dashboard");
    }
  }, []);

  // const renderErrorMessage = (name) =>
  //   name === errorMessages.name && (
  //   <div className="error">{errorMessages.message}</div>
  // );

  

  const defaultValues = {
    mail: "",
    code1: "",
    code2: "",
    code3: "",
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: defaultValues,
  });

  const onSubmit = async (credentials) => {
    // console.log(credentials)
    await axios
      .post(process.env.REACT_APP_AUTH_URL + "/forget", { credentials })
      .then(({data}) => {
        navigate("/authentification", {
          state: {
            loggedOut: true,
            message:
              data.message,
          },
        })
      })
      .catch(({response}) =>{
        // console.log(response.data.message)
        setError(true);
        setErrorMessages(response.data.message);
     })
  };

  return (
    <Box>
      <div className="Auth-form-container">
        <form className="Auth-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="Auth-form-content">
            <h3 className="Auth-form-title">Connexion</h3>
            <div className="form-group mt-3 container">
                
                  <label>Votre identifiant Fastrak</label>
                
                  <input
                    type="number"
                    className="form-control mt-1"
                    name="code1"
                    {...register("code1", {
                      required: true,
                      pattern: /^\d{3}$/,
                    })}
                  />
                  <input
                    type="number"
                    className="form-control mt-1"
                    name="code2"
                    {...register("code2", {
                      required: true,
                      pattern: /^\d{4}$/,
                    })}
                  />
                  <input
                    type="text"
                    className="form-control mt-1"
                    name="code3"
                    {...register("code3", {
                      required: true,
                      pattern: /^\w{2}$/,
                    })}
                  />
                  {(errors.code3?.type === "required" ||
                    errors.code2?.type === "required" ||
                    errors.code3?.type === "required") && (
                    <p className="text-error">Ce champ est obligatoire</p>
                  )}
                  {(errors.code1?.type === "pattern" ||
                    errors.code2?.type === "pattern" ||
                    errors.code3?.type === "pattern") && (
                    <p className="text-error">
                      Votre code n'est pas valide. Si vous ne connaissez pas
                      votre identifiant FASTRAK, faites-en la demande au centre
                      qui assure votre suivi
                    </p>
                  )}
            </div>
            <div className="form-group mt-3">
              <label>Mail</label>
              <input
                type="mail"
                className="form-control mt-1"
                placeholder="Saisissez votre adresse mail"
                name="mail"
                {...register("mail", {
                  required: true,
                })}
              />
            </div>
            {error && (
              <Alert severity="error">
                <AlertTitle>{errorMessages}</AlertTitle>
              </Alert>
            )}
            <div className="d-grid gap-2 mt-3">
              <button
                type="submit"
                style={{ backgroundColor: themeNutriFast.palette.primary.dark }}
                className="btn btn-nutrifast"
              >
                Envoyer
              </button>
            </div>
          </div>
        </form>
      </div>
    </Box>
  );
}


import { Controller } from "react-hook-form";
import { TextField } from "@mui/material";

 const FormInputText = ({ name, control,label,rules,...otherFields }) => {
  
  return (
    <>
    <Controller
        control={control}
        name={name}
        rules= {rules}
        render={( {field}) => 
            <TextField 
            {...field}
            {...otherFields}
            required= {rules && rules.required ? true : false}
            label={label}
            variant= 'outlined'
            fullWidth= {true}
            />
            
        }
    />
    </>
    )
};

export default FormInputText;
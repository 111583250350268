import { List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import React from 'react';

const QuestList = ({handleChangePage,list}) => {
    return (
        <>
        <List>
          {list && list.map((quest,index) => (
            <ListItem key={'list_'+quest.id}>
              <ListItemButton onClick={() => handleChangePage('/q/'+quest.nom,quest)}>
              {/* <ListItemButton component="a" href="/q/activites_physiques"> */}
                <ListItemText primary ={quest.nom} />
              </ListItemButton>
            </ListItem>
          ))}
          
        </List>
      </>
    );
};

export default QuestList;
import { Box, Button } from "@mui/material";
import axios from "axios";
import { getHeader } from "../../service/auth";
import { useEffect, useState } from "react";
import Bilan from "./Bilan";
import { motion } from 'framer-motion';
import { useLocation, useNavigate } from "react-router-dom";
import { Form } from "@formio/react";
import SnackAlert from "../../components/SnackAlert";
import { useSnackContext } from "../../components/SnackContext";
import themeNutriFast from "../../styles/NutriFastColors";
import { getResponse } from "../../service/submitService";

import MobileStepper from '@mui/material/MobileStepper';
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { useTheme } from "@emotion/react";

const Render = () => {
  const headers = getHeader();
  const [steps, setSteps] = useState([]);

  const { state } = useLocation();
  const [form, setForm] = useState({ components: [] });
  const [activeStep, setActiveStep] = useState(0);
  const [data, setData] = useState({});
  const [valide, setValide] = useState(0);
  let navigate = useNavigate();
  const { alert, handleClose, setAlert } = useSnackContext();
  const theme = useTheme();
  useEffect(() => {
    if (!state) {
      navigate("/dashboard");
    } else {
      let array = []
      axios
        .get(process.env.REACT_APP_QUESTIONNAIRE + "/" + state.id, { headers })
        .then(({ data }) => {
          
          setSteps(data);
       
          let json = JSON.parse(data[activeStep]?.form).components;
          //console.log(data)
          
          for (var i = 0; i < json.length; i++) {
            
            if(json[i].type === 'selectboxes'){
              array.push(json[i].key )
            }

          }
          

        // console.log(activeStep)
          setForm(
            JSON.parse(data[activeStep]?.form ? data[activeStep]?.form : null)
          );
        });

      axios
        .get(process.env.REACT_APP_QUESTIONNAIRE + "/data/" + state.id, {
          headers,
        })
        .then(({ data }) => {
          if (data[0]) {
            setValide(data[0].valide);
            let submission = {};
            Object.entries(data[0]).forEach((entries) => {
              if (!["createdAt", "updatedAt", "submit"].includes(entries[0])) {
                
                if(array.includes(entries[0])){
                  submission[entries[0]] = entries[1].split(',').reduce((a,v) => ({...a, [v] : true}),{})
                }
                else{
                  submission[entries[0]] = entries[1];
                }
                
              }
            });
            // console.log(submission)
            setData({ data: submission });
          }
        });
    }


  }, []);












  useEffect(() => {
  

    if (activeStep < 0) {
      setActiveStep(0);
    }
    if (steps[activeStep]?.form) {
      setForm(JSON.parse(steps[activeStep].form));
    }
  }, [activeStep]);

  const majData = (oldData, submission) => {
    let newData = oldData;
    Object.keys(submission).forEach((keys) => {
      if (["suivant", "retour", "data", "metadata"].includes(keys)) {
        newData[keys] = false;
      } else {
        newData[keys] = submission[keys];
      }

      // array[keys] = submission[keys]
    });
    // console.log(oldData)
    return { data: newData };
  };

  











  const changePage = (submission) => {
 
    if (submission.data.suivant) {
   
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      
      setData((old) => majData(old, submission.data));
  
    } else if (submission.data.retour) {
      //console.log("retour")
      if(activeStep > 0){
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        setData((old) => majData(old, submission.data));
      }
      else {
        navigate("/dashboard");
      }
      
    }
   
  };





  const onSubmit = async (submission) => {
   
    let response = getResponse(submission);

    if (Object.keys(response).length > 0) {
      await axios
        .post(
          process.env.REACT_APP_QUESTIONNAIRE + "/" + state.id,
          { data: response },
          { headers }
        )
        .then((response) => {
          changePage(submission);
        })
        .catch((err) => setAlert({message: "Une erreur s'est produite",severity: "error",open: true}))
    } else {
      changePage(submission);
    }
  };


const verifyStep = (activeStep) => {

if(activeStep+1 === steps.length){
      return " "
  } else if (activeStep > steps.length) {
   alert(activeStep  + " " + steps.length)
    return false 
   
  } else {
    console.log(activeStep)
    return steps[activeStep+1].title
   
  }
  
 

}

  const onClick = async () => {
    // axios.put(process.env.REACT_APP_INSCRIPTION_URL,profil)
    axios
      .post(
        process.env.REACT_APP_QUESTIONNAIRE + "/valide/" + state.id,
        {},
        { headers }
      )
      .then(() => {
        setValide(1)
        setAlert({
          message: "Questionnaire validé",
          severity: "success",
          open: true,
        });
      });
  };

  return (
    <motion.div initial={{opacity:0}} animate={{opacity:1}} transition={{ duration: 2 }}>
    <Box
      sx={{
        "& .MuiTextField-root": { m: 1, width: "100%" },
        width: "90%",
        margin: "auto",
      }}
    >
      <h2  style={{color:themeNutriFast.palette.primary.dark}}  className="title-dashbord">{state ? state.name : null}</h2>

      <Box sx={{ width: "100%" }}>
    
        {valide || activeStep >= steps.length ? (
          <>
            <Bilan data={data} steps={steps} />

            {!valide ? (
              <>
                <Button
                  onClick={() => {
                    setActiveStep((prevActiveStep) => prevActiveStep - 1);
                  }}
                >
                  {" "}
                
                  Retour
                </Button>
                <Button onClick={onClick}> Valider</Button>
              </>
            ) : null}
            <SnackAlert
              open={alert.open}
              severity={alert.severity}
              message={alert.message}
              handleClose={handleClose}
            />
          </>
        ) : (
          <>
            {




<div   className="wrap_mobile_stepper">
  <MobileStepper
    variant="progress"
    steps={steps.length}
    position="static"
   
    activeStep={activeStep}
    sx={{ maxWidth: 400, flexGrow: 1 }}
    nextButton={
      <Button size="small" disabled={activeStep === steps.length}>
     { verifyStep(activeStep) }
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </Button>
    }
    backButton={
      <Button size="small" disabled={activeStep === 0}>
          
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      {  activeStep < 1  ? ""  :  steps[activeStep-1].title }
      </Button>
    }
 />
    
 
    

    { steps[activeStep].title}
    
    


     </div>


  



      /*




   {steps.map((label) => {
    return (
      <div     key={label.title} >
        <div >{label.title}</div>
      </div>
    )
  })}



(() => {
  if (activeStep === steps.length)
     return null
  if (steps.length > 5)
     return <StepNutrifast steps={steps_affiches}  activeStep={1}/>
  else
    return  <Stepper   sx={{ mb: 5 }} activeStep={activeStep}>
     {steps_affiches.map((label, index) => {
       const stepProps = {};
       const labelProps = {};

       return (
         <Step key={label.title} {...stepProps}>
           <StepLabel {...labelProps}>{label.title}</StepLabel>
         </Step>
       );
     })}
   </Stepper>
})()

*/


/*
  
         activeStep === steps.length ? null : (
              <Stepper  sx={{ mb: 5 }} activeStep={activeStep}>
                {steps.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};

                  return (
                    <Step key={label.title} {...stepProps}>
                      <StepLabel {...labelProps}>{label.title}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
            )
       */
            
/*
            
  
            activeStep === steps.length ? null : (
              <StepNutrifast steps={steps_affiches}  activeStep={activeStep}/>
                  )

*/



            }



            <Form form={form} onSubmit={onSubmit} submission={data} />
          </>
        )}
      </Box>
    </Box>
    </motion.div>
  );
};

export default Render;

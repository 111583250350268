import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { getHeader } from '../service/auth';
import FormInputText from '../components/FormInputText';
import SnackAlert from '../components/SnackAlert';
import themeNutriFast from '../styles/NutriFastColors';
import { useForm } from 'react-hook-form';
import { Box } from '@mui/material';
 
const Aide = () => {

   

    const { handleSubmit, control, reset, formState} = useForm({ defaultValues : {
        message: "",
        mail: "",
    }});

    const headers = getHeader();
    const [open, setOpen] = useState(false)
    const [message, setMessage] = useState('')
    const [severity, setSeverity] = useState('info')

    const onSubmit = async (data) => {
        await axios
            .post(process.env.REACT_APP_USER_URL+"/aide", { data }, { headers })
            .then(() => {

                setMessage('Votre message a été envoyé')
                setSeverity('success')
                setOpen(true)
            })
            .catch((err) => {
                setMessage("Une erreur s'est produite")
                setSeverity('error')
                setOpen(true)

            });
    };

    useEffect(() => {
        if (formState.isSubmitSuccessful) {
            reset();
        }
    }, [formState, reset]);

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false)
    };

    return (
        <Box sx={{
            "& .MuiTextField-root": { m: 1, width: "50%"}, textAlign:"center"
    
          }}>
            <h2> Besoin d'aide ?</h2>
            <p>Vous pouvez nous contacter en utilisant le formulaire ci-dessous.</p>
            <form onSubmit={handleSubmit(onSubmit)} className='form'>
                <FormInputText label="Mon Email" name="mail" control={control} />
                <FormInputText multiline rows={4} label="Message" name="message" control={control} />
                <button className="button btn-nutrifast" type="submit" style={{ margin: 'auto', width: "50%", backgroundColor: themeNutriFast.palette.primary.dark }}>
                    Envoyer
                </button>
            </form>
            <SnackAlert
                open={open}
                severity={severity}
                message={message}
                handleClose={handleClose}
            />
        </Box>
    )
}


export default Aide

import { Box } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { getHeader } from "../service/auth";
import FormInputText from "./FormInputText";
import FormDatePicker from "./FormDatePicker";
import dayjs from "dayjs";
import { FormInputRadio } from "./FormInputRadio";
import SnackAlert from "./SnackAlert";
import themeNutriFast from "../styles/NutriFastColors";
import { motion } from 'framer-motion';
const major = dayjs().subtract(16, 'year');

const ProfilForm = ({defaultValues,admin}) => {

  const { handleSubmit, setValue, control, getValues,register } = useForm({
    defaultValues
  });
//   setValue(defaultValues)
  const headers = getHeader();
  const [open,setOpen] = useState(false)
  const [message,setMessage] = useState('')
  const [severity,setSeverity] = useState('info')

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false)
  };

  useEffect(()=>{
    if(defaultValues.name !== ''){
        setValue("username", defaultValues.name);
        setValue("mail", defaultValues.mail);
        // setValue("prenom", defaultValues.prenom);
        // setValue("nom", defaultValues.nom);
        setValue("birth_date", dayjs(defaultValues.birth_date));
        // setValue('telephone',defaultValues.telephone)
    }
  },[defaultValues])

  

  const onSubmit = async (data) => {
    
    await axios
      .post(process.env.REACT_APP_USER_URL, { data }, { headers })
      .then(() => {
        
        setMessage('Vos données ont été mis à jour')
        setSeverity('success')
        setOpen(true)
      })
      .catch(() => {
        setMessage("Une erreur s'est produite")
        setSeverity('error')
        setOpen(true)

      });
  };

  let buttons = [
    { value: true, label: "Administrateur" },
    { value: false, label: "Volontaire" },
  ];

  

  return (
    <motion.div initial={{opacity:0}} animate={{opacity:1}} transition={{ duration: 2 }} >
    <Box 
      sx={{
        "& .MuiTextField-root": { m: 1, width: "50%"}, textAlign:"center"

      }}
    >
      <h2    style={{color:themeNutriFast.palette.primary.dark}}  className="title-dashbord">Profil</h2>

      <form onSubmit={handleSubmit(onSubmit)} className="form">
        <section>
          <FormInputText label="pseudo" name="username" control={control} />
        </section>
        <section>
          <FormInputText label="email" name="mail" control={control} />
        </section>
        {/* <section>
          <FormInputText label="Prénom" name="prenom" control={control} />
        </section>
        <section>
          <FormInputText label="Nom" name="nom" control={control} />
        </section>
        <section>
          <FormInputText label="Téléphone" name="telephone" control={control} />
        </section> */}
        <section>
          <FormDatePicker
            name="birth_date"
            views={["month", "year"]}
            label={"Date de naissance"}
            value={getValues("birth_date")}
            control={control}
            maxDate={major}
          />
        </section>
        
        {admin &&
        <div className="user-edit-role-field">
         <input type="hidden" name="id" value={defaultValues.id} {...register("id")}  />
         <FormInputRadio 
         
          label="Role ?"
          name="admin"
          buttons={buttons}
          control={control}
          rules={{ required: true }}
          xs={12}
        />  
        </div>
         }
       
        <button  className="button btn-nutrifast" type="submit" style={{margin:'auto', width:"50%",backgroundColor:themeNutriFast.palette.primary.dark}}>
          Envoyer
        </button>
      </form>
      <SnackAlert
        open={open}
        severity={severity}
        message={message}
        handleClose={handleClose}
      />
    </Box>
    </motion.div>
  );
};

export default ProfilForm;

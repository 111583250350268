import { Navigate } from "react-router-dom";
import { isLogged } from "../service/auth";

 

const Firewall = ({children}) => {
    
    if(!isLogged()){
        return <Navigate to="/authentification"/>
    }
    return children;
};

export default Firewall;
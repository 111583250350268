
import axios from "axios";
import React, { useEffect, useState } from "react";
import { getHeader } from "../service/auth";
import dayjs from "dayjs";
import ProfilForm from "../components/ProfilForm";
// import dayjs from 'dayjs';

export default function Profil() {
  const headers = getHeader();
  const [infos,setInfos] = useState({
    username: "",
    mail: "",
    birth_date:dayjs(),
    prenom:"",
    nom:"",
    telephone:""
  })

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_USER_URL, { headers })
      .then(({ data }) => {
        setInfos(data)
      })
  }, []);

  

  

//  console.log(infos)  

  return <ProfilForm 
          defaultValues={infos}
          admin= {false}
        />
}

import { Alert, Snackbar, Stack } from '@mui/material';
import React from 'react';

const SnackAlert = ({severity,message,open,handleClose}) => {

    

    const MuiAlert = React.forwardRef(function MuiAlert(props, ref) {
        return <Alert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    

    return (
        <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <MuiAlert
            onClose={handleClose}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </MuiAlert>
        </Snackbar>
      </Stack>
    );
};

export default SnackAlert;
import axios from "axios";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { getHeader } from "../../../service/auth";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { StyledTableRow } from "../../../utils/styledTable";
import {
  AutoFixHigh,
  Construction,
  DatasetLinked,
  Delete,
  Edit,
  FileDownload,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import AddQuestionnaire from "./AddQuestionnaire";
import SnackAlert from "../../../components/SnackAlert";
import { useSnackContext } from "../../../components/SnackContext";
import EditQuestionnaire from "./EditQuestionnaire";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { color, motion } from "framer-motion";
import themeNutriFast from "../../../styles/NutriFastColors";

const pAnuaireBtn = {
  color: "grey",
  display: "contents",
  float: "left",
};

const BtnAnnuaire = {
  /* color: "grey",*/
  display: "contents",
  float: "left",
};

const padTr = {
  padding: "6px",
};

const tablecelladd = {
  color: themeNutriFast.palette.primary.dark + " !important",
};

const GestionQuestionnaire = () => {
  const [data, setData] = useState([]);
  const [questionnaires, setQuestionnaires] = useState([]);
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [defaultValues, setValues] = useState({
    questionnaire_id: "",
    name: "",
  });
  const { alert, handleClose, setAlert } = useSnackContext();
  const headers = getHeader();
  let navigate = useNavigate();

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_ADMIN + "/questionnaire", { headers })
      .then((response) => {
        setQuestionnaires(response.data);
      });
    // axios
    //   .get(process.env.REACT_APP_ADMIN + "/questionnaire/data", { headers })
    //   .then((response) => {
    //     setData(response.data);
    //   });
  }, []);

  const handleCSV = async (event, done, id) => {
    await axios
      .get(process.env.REACT_APP_ADMIN + "/questionnaire/data/" + id, {
        headers,
      })
      .then((response) => {
        setData(response.data);
      });
    done(true);
  };

  const handleGenerate = async (id) => {
    await axios
      .get(process.env.REACT_APP_ADMIN + "/generate/" + id, { headers })
      .then(() => {
        setAlert({
          message: "Table créé",
          severity: "success",
          open: true,
        });
      })
      .catch(
        setAlert({
          message: "Une erreur s'est produite",
          severity: "error",
          open: true,
        })
      );
  };

  const handleDelete = async (id) => {
    await axios
      .delete(process.env.REACT_APP_ADMIN + "/quest/" + id, { headers })
      .then(() => {

        let newdata = questionnaires.filter(function(el) { return el.id !== id; });

        setQuestionnaires(newdata)
        setAlert({
          message: "Questionnaire supprimé",
          severity: "success",
          open: true,
        });
      })
      .catch(
        setAlert({
          message: "Une erreur s'est produite",
          severity: "error",
          open: true,
        })
      );
  };

  const handleAssoc = (id) => {
    navigate("/admin/assoc_users", { state: id });
  };

  const editName = (questName, id) => {
    setValues({ questionnaire_id: id, name: questName });
    setOpenEdit(true);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 2 }}
    >
      <Paper style={{ padding: 30 }}>
        {/* <CSVLink
        data={data}
        style={pAnuaireBtn}
        filename={"data.csv"}
        separator={";"}
        title="Exporter les données au format CSV"
        target="_blank"
      >
        Exporter les données au format CSV
      </CSVLink> */}
        <TableContainer component={Paper}>
          {questionnaires && (
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell style={padTr}>
                    <p style={BtnAnnuaire}>Nom</p>
                  </TableCell>
                  <TableCell style={padTr}>
                    <p style={BtnAnnuaire}>Actions</p>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {questionnaires.map((row, index) => (
                  <StyledTableRow
                    key={"list" + index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell>
                      {/* <Button
                      style={pAnuaireBtn}
                      title="Exporter les données"
                      onClick={() => handleCSV(row)}
                    >
                      <FileDownload />
                    </Button> */}
                      <CSVLink
                        data={data}
                        asyncOnClick={true}
                        filename={row.name}
                        onClick={(event, done) =>
                          handleCSV(event, done, row.id)
                        }
                      >
                        <FileDownload
                          style={{ color: themeNutriFast.palette.primary.dark }}
                          className="tablecelladd"
                        />
                      </CSVLink>

                      <Button
                        style={pAnuaireBtn}
                        title="Associer un questionnaire"
                        onClick={() => handleAssoc(row.id)}
                      >
                        <DatasetLinked
                          style={{ color: themeNutriFast.palette.primary.dark }}
                          className="tablecelladd"
                        />
                      </Button>

                      <Button
                        style={pAnuaireBtn}
                        title="Editer le nom"
                        onClick={() => editName(row.name, row.id)}
                      >
                        <Edit
                          className="tablecelladd"
                          style={{ color: themeNutriFast.palette.primary.dark }}
                        />
                      </Button>

                      <Button
                        style={pAnuaireBtn}
                        title="Editer le questionnaire"
                        onClick={() =>
                          navigate("/admin/questionnaire/show", {
                            state: row.id,
                          })
                        }
                      >
                        <AutoFixHigh
                          className="tablecelladd"
                          style={{ color: themeNutriFast.palette.primary.dark }}
                        />
                      </Button>

                      <Button
                        style={pAnuaireBtn}
                        title="Générer la table de réponses"
                        onClick={(event, done) =>
                          handleGenerate(row.id)
                        }
                      >
                        <Construction
                          className="tablecelladd"
                          style={{ color: themeNutriFast.palette.primary.dark }}
                        />
                      </Button>

                      <Button
                        style={pAnuaireBtn}
                        title="Supprimer le questionnaire"
                        onClick={() =>
                          handleDelete(row.id)
                        }
                      >
                        <Delete
                          className="tablecelladd"
                          style={{ color: themeNutriFast.palette.primary.dark }}
                        />
                      </Button>
                    </TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </TableContainer>

        <div className="fab-container">
          <span>Ajouter un questionnaire</span>
          <div
            style={{ backgroundColor: themeNutriFast.palette.primary.dark }}
            className="button-add iconbutton"
            title="Ajouter un questionnaire"
            onClick={() => {
              // navigate("/admin//questionnaire/add_questionnaire");
              setOpen(true);
            }}
          >
            <span>
              <AddOutlinedIcon></AddOutlinedIcon>
            </span>
          </div>
        </div>

        <AddQuestionnaire
          openDialog={open}
          setOpenDialog={setOpen}
          setQuestionnaires={setQuestionnaires}
        />
        <EditQuestionnaire
          openDialog={openEdit}
          setOpenDialog={setOpenEdit}
          setQuestionnaires={setQuestionnaires}
          defaultValues={defaultValues}
        />
        <SnackAlert
          open={alert.open}
          severity={alert.severity}
          message={alert.message}
          handleClose={handleClose}
        />
      </Paper>
    </motion.div>
  );
};

export default GestionQuestionnaire;

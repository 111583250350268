import axios from "axios";
import React, { useEffect, useState } from "react";
import { getHeader } from "../../../service/auth";
import { useNavigate, useLocation } from "react-router-dom";
import { List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { motion } from "framer-motion";

const ShowUser = () => {
  const headers = getHeader();
  const [list, setList] = useState([]);
  let navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_ADMIN + "/questionnaire/user/" + state.id, {
        headers,
      })
      .then(({ data }) => {
        setList(data);
      });
  }, []);

  const handleChangePage = (page,quest) => {
    if (page) navigate(page, { state: {quest: quest, user_id : state.id} });
  };

  return (
    <motion.div initial={{opacity:0}} animate={{opacity:1}} transition={{ duration: 2 }}>
      <List>
        {list &&
          list.map((quest, index) => (
            <ListItem key={"list_" + index}>
              <ListItemButton className="item-user-show"
                onClick={() => handleChangePage("/admin/q/" + quest.name,quest)}
              >
                {/* <ListItemButton component="a" href="/q/activites_physiques"> */}
                <ListItemText primary={quest.name} />
              </ListItemButton>
            </ListItem>
          ))}
      </List>
    </motion.div>
  );
};

export default ShowUser;

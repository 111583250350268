import { createTheme } from '@mui/material/styles';
import { green, purple } from '@mui/material/colors';

const themeNutriFast = createTheme({
  palette: {
    primary: {
      main: '#2E151B',
      light: '#caf0f8',
      dark: '#023e8a',
      contrastText: '#fff',
    },

    action: {
      hover: green[700]
    },
    secondary: {
      main: green[500],
    },
  },
});


export default themeNutriFast;
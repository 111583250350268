import { FormBuilder } from "@formio/react";
import { useEffect, useState } from "react";
import "../styles/builder.css";
import { Button, Grid } from "@mui/material";
import { getHeader } from "../service/auth";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import SnackAlert from "./SnackAlert";

const FormGenerator = () => {
  const headers = getHeader();
  let navigate = useNavigate();

  const [jsonSchema, setSchema] = useState({
    components: [
      {
        label: "Columns",
        columns: [
          {
            components: [
              {
                label: "Retour",
                theme: "primary",
                type: "button",
              },
            ]
          },
          {
            components: [
              {
                label: "Envoyer",
                theme: "primary",
                key: "suivant",
                type: "button",
              },
            ]
          },
        ],
        type: "columns",
      },
    ],
  });

  const { state } = useLocation();

  const [alert, setAlert] = useState({
    message: "",
    severity: "info",
    open: false,
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlert({ message: "", severity: "info", open: false });
  };

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_ADMIN + "/form/" + state.id, { headers })
      .then((response) => {
        let form = JSON.parse(response.data.form);
        // console.log(response.data.form)
        // console.log(form)
        setSchema(form);
      });
  }, []);
  const Retour = () => {
    
    navigate("/admin/questionnaire/show", { state:state.quest_id })
  }
  const onSubmit = () => {
    axios
      .post(
        process.env.REACT_APP_ADMIN + "/" + state.id,
        { jsonSchema, id: state.id },
        { headers }
      )
      .then((response) => {
        setAlert({ message: "Enregistré", severity: "success", open: true });
      })
      .catch(
        setAlert({
          message: "Une erreur s'est produite",
          severity: "error",
          open: true,
        })
      );
  };

  const onFormChange = (schema) => {
    setSchema({ ...schema, components: [...schema.components] });
  };
  return (
    <>
    <Grid container >
      <Grid item md={12}>
        <FormBuilder   form={jsonSchema} onChange={onFormChange} />
      </Grid>
      <Grid item md={6}>
        <Button variant="contained" color="error"  onClick={Retour}> Retour</Button>
      </Grid>
      <Grid item md={6}>
        <Button  variant="contained" color="success"  onClick={onSubmit}> Enregistrer</Button>
      </Grid>
      <SnackAlert
        open={alert.open}
        severity={alert.severity}
        message={alert.message}
        handleClose={handleClose}
      />

    </Grid>
    </>
  );
};

export default FormGenerator;

import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";
import { Form } from "@formio/react";
import themeNutriFast from "../../styles/NutriFastColors";
import SnackAlert from "../../components/SnackAlert";
import { useSnackContext } from "../../components/SnackContext";
import { useNavigate } from "react-router-dom";

const Bilan = ({ data, steps, admin = false, state }) => {
  const { alert, handleClose, setAlert } = useSnackContext();
  let navigate = useNavigate();

  
  
  const modifier = (index) => {
    
    navigate("/admin/edit/quest", { state: {steps : JSON.parse(steps[index].form), data: data,info : state} });
  };

 
  return (
    <>
      <h2
        style={{ color: themeNutriFast.palette.primary.dark }}
        className="title-dashbord"
      >
        Bilan
      </h2>
      {steps.map((row, index) => ( 
        <Accordion key={row.title}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            {row.title}
          </AccordionSummary>
          <AccordionDetails>
            <Form
              form={JSON.parse(row.form)}
              options={{ readOnly: true}}
              submission={data}
            />
          </AccordionDetails>
          {admin && (
            <AccordionActions>
              <Button onClick={() => modifier(index)}>Modifier</Button>
            </AccordionActions>
          )}
        </Accordion>
      ))}
      <SnackAlert
              open={alert.open}
              severity={alert.severity}
              message={alert.message}
              handleClose={handleClose}
            />
    </>
  );
};

export default Bilan;

import { Alert, AlertTitle } from "@mui/material";
import axios from "axios";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import themeNutriFast from "../styles/NutriFastColors";
import { isLogged } from "../service/auth";

export const ChangeMdp = () => {
  const { token } = useParams();

  const title = "Changement de mot de passe";

  const defaultValues = {
    password: "",
    confirm_password: "",
  };

  useEffect(() => {
    if (isLogged()) {
      navigate("/dashboard");
    } else {
      axios
        .post(process.env.REACT_APP_AUTH_URL + "/test_token", {token} )
        .then(({data} ) => {
          if (data) {
            navigate("/authentification");
          }
        })
        .catch(( {response} ) => {
                 setError("root.serverError", {
            type: "500",
            message: response.data.message,
          });
        });
    }
  }, []);

  let navigate = useNavigate();

  const onSubmit = (data) => {
    let pass = true;

    if (data.confirm_password !== data.password) {
      pass = false;
      setError("password", {
        type: "custom",
        message: "Les mots de passe ne sont pas identiques",
      });
    }

    if (pass) {
      data.token = token
      axios
        .post(process.env.REACT_APP_AUTH_URL+"/change_mdp", data)
        .then(() =>
          navigate("/authentification", {
            state: {
              loggedOut: true,
              message:
                "Votre mot de passe a bien été modifié. Vous pouvez maintenant vous connecter",
            },
          })
        )
        .catch(({ response }) => {
          setError("root.serverError", {
            type: "500",
            message: response.data.message,
          });
        });
    }
  };

  const {
    setError,
    formState: { errors },
    register,
    handleSubmit,
  } = useForm({
    defaultValues: defaultValues,
  });

  return (
    <div className="Auth-form-container">
      <form className="Auth-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="Auth-form-content">
          <h3 className="Auth-form-title">{title}</h3>

          <div className="form-group mt-3">
            <label>Mot de passe</label>
            <input
              type="password"
              className="form-control mt-1"
              name="password"
              {...register("password", { required: true })}
            />
            {errors.mail?.type === "required" && (
              <p className="text-error">Ce champ est obligatoire</p>
            )}
          </div>
          <div className="form-group mt-3">
            <label>Confirmer votre mot de passe</label>
            <input
              type="password"
              className="form-control mt-1"
              name="confirm_password"
              {...register("confirm_password", { required: true })}
            />
            {errors.password?.type === "required" && (
              <p className="text-error">Ce champ est obligatoire</p>
            )}
            {errors.password?.type === "custom" && (
              <p className="text-error">{errors.password.message}</p>
            )}
          </div>
          {errors?.root?.serverError.type === "500" && (
            <Alert severity="error">
              <AlertTitle>{errors?.root?.serverError.message}</AlertTitle>
            </Alert>
          )}
          <div className="d-grid gap-2 mt-3">
            <button
              type="submit"
              style={{ backgroundColor: themeNutriFast.palette.primary.dark }}
              className="btn-nutrifast"
            >
              Envoyer
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

import React from 'react'
import { useLocation } from 'react-router-dom';

export const ShowPublications = () => {
    const { state } = useLocation();
  return (
    <div>
    <object data={state} type="application/pdf" width="100%" height="1000px">
      {/* <p>Si le lien de se charge pas cliquez <a href={require('../asset/plan_acces.pdf')}>ici</a>.</p> */}
    </object>
  </div>

  )
}

import { createContext, useContext, useState } from "react";
const SnackContext = createContext()


export const  useSnackContext = () => {
  return useContext(SnackContext);
};

export const SnackProvider = ({ children }) => {
  const [alert, setAlert] = useState({
    message: "",
    severity: "info",
    open: false,
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlert({ message: "", severity: "info", open: false });
  };

  return (
    <SnackContext.Provider value={{ alert, setAlert,handleClose }}>
      {children}
    </SnackContext.Provider>
  );
};
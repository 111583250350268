import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import FormInputText from "../../../components/FormInputText";
import axios from "axios";
import { getHeader } from "../../../service/auth";
import { useForm } from "react-hook-form";
import { useSnackContext } from "../../../components/SnackContext";
import themeNutriFast from "../../../styles/NutriFastColors";

const AddQuestionnaire = ({openDialog,setOpenDialog,setQuestionnaires}) => {
  const defaultValues = { name: "", table_result: "" };
  const { handleSubmit, control } = useForm({ defaultValues });
  const { setAlert } = useSnackContext();
  const headers = getHeader();

  const handleClose = () => {
    setOpenDialog(false);
  };

  const onSubmit = async (data) => {
    await axios
      .put(process.env.REACT_APP_ADMIN, { data }, { headers })
      .then((response) => {
        setAlert({
          message: "Questionnaire ajouté",
          severity: "info",
          open: false,
        });
        setOpenDialog(false);
        setQuestionnaires(current => [...current,{ id: response.data.id, name: data.name }]);
      });
  };

  return (
    <Dialog open={openDialog} onClose={handleClose}>
      <DialogTitle>Ajout de questionnaire</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)} className="form">
          <section className="item_field_addquest_dialog">
            <FormInputText  label="Nom" name="name" control={control} />
          </section>
          <section className="item_field_addquest_dialog">
            <FormInputText 
              
              label="Nom technique"
              name="table_result"
              control={control}
            />
          </section>
          <DialogActions>
            <button  style={{backgroundColor:themeNutriFast.palette.primary.dark}} className="btn-nutrifast" type="submit">
              Envoyer
            </button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AddQuestionnaire;

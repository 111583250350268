import axios from "axios";
import React, { useEffect, useState } from "react";
import { getHeader } from "../../../service/auth";
import { useLocation } from "react-router-dom";
import Bilan from "../../questionnaire/Bilan";
import { Button } from "@mui/material";
import { useSnackContext } from "../../../components/SnackContext";
import SnackAlert from "../../../components/SnackAlert";

const BilanAdmin = () => {
  const headers = getHeader();
  const { state } = useLocation();


  const [valide, setValide] = useState(0);
  const [hasData, setHasData] = useState(false);
  const [data, setData] = useState({})
  const [steps, setSteps] = useState([]);
  const { alert, handleClose, setAlert } = useSnackContext();


  useEffect(() => {
    axios
      .get(process.env.REACT_APP_ADMIN + "/questionnaire/user/data/" + state.user_id + "/" + state.quest.id, {
        headers,
      })
      .then(({ data }) => {


        if (data.length > 0) {
          setData({ data: data[0] })
          setValide(data[0]["valide"]);
          setHasData(true);
        }

        // }
      });

    axios
      .get(process.env.REACT_APP_QUESTIONNAIRE + "/" + state.quest.id, { headers })
      .then(({ data }) => {
        setSteps(data);
      });
  }, []);

  const invalidate = () => {
    axios
      .get(
        process.env.REACT_APP_ADMIN + "/invalidate/" + state.quest.id + "/" + state.user_id,
        { headers }
      )
      .then(() => {
        setAlert({
          message: "Questionnaire invalidé",
          severity: "success",
          open: true,
        });
      });
  }

  const removeLine = () => {
    axios
      .delete(
        process.env.REACT_APP_ADMIN + "/quest/" + state.quest.id + "/" + state.user_id,
        { headers }
      )
      .then(() => {
        setHasData(false);
        setAlert({
          message: "Vous avez supprimé les réponses de ce volontaire",
          severity: "success",
          open: true,
        });
      });
  }

  return (
    <>
      {hasData ? (
        <>
          <Bilan data={data} steps={steps} admin={true} state={state} />
          <Button

            onClick={invalidate}
          >
            Invalider le questionnaire
          </Button>
          <SnackAlert
            open={alert.open}
            severity={alert.severity}
            message={alert.message}
            handleClose={handleClose}
          />
          <Button

            onClick={removeLine}
          >
            Supprimer toutes les réponses
          </Button>
          <SnackAlert
            open={alert.open}
            severity={alert.severity}
            message={alert.message}
            handleClose={handleClose}
          />
        </>
      ) : (
        <div>Le volontaire n'a pas encore validé son questionnaire</div>
      )}
    </>
  );
};

export default BilanAdmin;

import  funder  from "./../asset/logos/EN-Funded by the EU-POS.jpg"
import universite from "./../asset/logos/Universite Sorbonne Paris Nord.png"
import genial  from "./../asset/logos/footer_logo_GENIAL.png"
import eren from  "./../asset/logos/CRESS-EREN_couleurs_transparent.png"


function LogosFooter(){



return(
    <div className="wrap_logos_footer">
        <img  src={genial} alt="Genial" />
        <img  src={funder} alt="Funded by the EU" />
        <img  src={universite} alt="Universite Sorbonne Paris Nord" />

        <img  src={eren} alt="Funded by the EU" />

<p className="text_footer">Le protocole de l'étude Nutri-CIRR bénéficie d'un avis favorable du Comité de Protection des Personnes Sud Méditerranée I (n°2023-A01392-43). Il s'inscrit dans la méthodologie de référence MR003 de la Cnil. </p>
    </div>
)

}


/*
<div className='block_item_info wrap_images'>
<img  src={universite} alt="Universite Sorbonne Paris Nord" />
<img  src={eren} alt="Funded by the EU" />
</div>
*/
export default LogosFooter
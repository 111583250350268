import React, { useState } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Controller } from "react-hook-form";
import dayjs from "dayjs";


const FormDatePicker = ({ name, control, label, rules, views ,value,...props}) => {
  const [reqDate, setreqDate] = useState(dayjs(new Date(value)));
  
  return (
    <Controller
      name={name}
      defaultValue={reqDate}
      control={control}
      rules={rules}
      
      render={({field: { onChange, ...restField }, fieldState: { error } }) => (
        
        <DatePicker
          onChange={(event) => {  onChange(event); setreqDate(event); }}
          {...restField}
          slotProps={{
            textField: {
              variant: 'outlined',
              placeholder: '',
              error: !!error,
              helperText: error?.message,
            },
          }}
          required={rules && rules.required ? true : false}
          label={label}
          views={views}
          {...props}
        />
      )}
    />
  );
};

export default FormDatePicker;

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import FormInputText from "../../../components/FormInputText";
import axios from "axios";
import { getHeader } from "../../../service/auth";
import { useForm } from "react-hook-form";
import { useSnackContext } from "../../../components/SnackContext";
import { useEffect } from "react";
import themeNutriFast from "../../../styles/NutriFastColors";

const EditPage = ({ openDialog, setOpenDialog, setPage, defaultValues }) => {
  // const defaultValues = { title: title, questionnaire_id: quest_id,id:id };
  const { handleSubmit, control, register,setValue } = useForm({ defaultValues });
  const { setAlert } = useSnackContext();
  const headers = getHeader();

  const handleClose = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    
    setValue('title',defaultValues.title)
    setValue('questionnaire_id',defaultValues.questionnaire_id)
    setValue('id',defaultValues.id)
  })

  const onSubmit = async (data) => {
    await axios
      .post(process.env.REACT_APP_ADMIN + "/form_name", { data }, { headers })
      .then((response) => {
        setAlert({
          message: "Page modifié",
          severity: "info",
          open: false,
        });
        setOpenDialog(false);
        setValue('title','')
        setPage(response.data);
      });
  };

  return (
    <Dialog open={openDialog} onClose={handleClose}>
      <DialogTitle>Edition de Page</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)} className="form">
          <section>
            <FormInputText label="Titre" name="title" control={control} />
          </section>
          <input
            type="hidden"
            name="questionnaire_id"
            required={true}
            {...register("questionnaire_id")}
          />
          <input type="hidden" name="id" required={true} {...register("id")} />
          <DialogActions>
            <button  style={{backgroundColor:themeNutriFast.palette.primary.dark}} className="btn-nutrifast" type="submit">
              Envoyer
            </button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default EditPage;

import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getResponse } from '../../service/submitService';
import { getHeader } from '../../service/auth';
import axios from 'axios';
import SnackAlert from '../../components/SnackAlert';
import { useSnackContext } from '../../components/SnackContext';
import { Form } from '@formio/react';

const AdminRender = () => {
    const { state } = useLocation();
    const headers = getHeader();
    const { alert, handleClose, setAlert } = useSnackContext();
    let navigate = useNavigate();

    

    

    const onSubmit = async (submission) => {
        let response = getResponse(submission);
        // console.log(state.info)
        
        if (Object.keys(response).length > 0) {
          await axios
            .post(
              process.env.REACT_APP_ADMIN + "/quest_admin/" + state.info.quest.id+"/"+ state.info.user_id,
              { data: response },
              { headers }
            )
            .then((return_data) => {
              // console.log(response.user_id)
              navigate("/admin/q/" + state.info.quest.name, { state: state.info });
              
            })
            .catch((err) => setAlert({message: "Une erreur s'est produite",severity: "error",open: true}))
        }
      };

    return (
        <>
        
        <Form form={state.steps} onSubmit={onSubmit}  submission={state.data} />
        <SnackAlert
              open={alert.open}
              severity={alert.severity}
              message={alert.message}
              handleClose={handleClose}
            />
        </>
    );
};

export default AdminRender;
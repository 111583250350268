import { motion } from 'framer-motion';
import React from 'react';
import { Outlet } from 'react-router-dom';
import themeNutriFast from '../../styles/NutriFastColors';

const Admin = () => {
    return (
        <motion.div initial={{opacity:0}} animate={{opacity:1}} transition={{ duration: 2 }}>
        <h2  style={{color:themeNutriFast.palette.primary.dark}}  className='title-dashbord'> Administration </h2>
        <Outlet/>
        </motion.div>
    );
};

export default Admin;
const getResponse = (data) => {
    let response = {};

    Object.entries(data.data).forEach((entries) => {
      if (
        ![
          "suivant",
          "retour",
          "createdAt",
          "updatedAt",
          "valide",
          "bilan",
          "user_id",
          "data",
          "metadata",
          "submit",
        ].includes(entries[0])
      ) {
        if (typeof entries[1] !== "object" || entries[1] === null) {
          response[entries[0]] = entries[1];
        } else {
          let array = [];
          
          Object.entries(entries[1]).forEach((checkbox) => {
            
            if (checkbox[1]) {
              array.push(checkbox[0]);
            }
          });
          
          response[entries[0]] = array.join(",");
        }
      }
    });

    return response;
  };


  

  module.exports = {getResponse}
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import { Controller } from "react-hook-form";



export const FormInputRadio= ({name,control,buttons,label,rules}) => {

  const generateButtons = (buttons) => {
    return buttons.map((button) => {   
      return (
        <FormControlLabel
        key={'radio'+button.value}
        value={button.value}
        control={<Radio />}
        label={button.label}
      />
      );
    });
  };

  
  return <Controller
  render={({ field }) => (
    <>
        <FormControl sx={{ width:'100%',my: 5}} required= {rules.required ? true : false}>
          {/* <FormLabel id={'label_'+name}>{label}</FormLabel> */}
          <label>{label}</label>
          <RadioGroup aria-labelledby={'label_'+name} row {...field}>
          {generateButtons(buttons)}
          </RadioGroup>
        </FormControl>
    </>
  )}
  name={name}
  control={control}
  rules={rules}
/>
  
};
const { default: jwtDecode } = require("jwt-decode")


const saveToken = (token) => {
    localStorage.setItem('token',token)
}

const logout = () => {
    localStorage.removeItem('token')
}

const isLogged = () => {
    const isTokenExpired = token => Date.now() >= (JSON.parse(window.atob(token.split('.')[1]))).exp * 1000

    let token = localStorage.getItem('token')
    // console.log(isTokenExpired(token))
    return token && !isTokenExpired(token)
}

const getToken = () => {
    let token = localStorage.getItem('token')
    return token
}

const getHeader = () => {
    let header = {'Authorization': 'Bearer ' + getToken()}

    return header
}

const getUser = () => {
    if(isLogged()){
        let token = getToken()
        let user = jwtDecode(token)
        return {admin : user.admin, id: user.id}
    }

    return null
    
}

module.exports = {saveToken,logout,isLogged,getToken,getHeader,getUser}
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from '../pages/Home';
import Dashboard from '../pages/Dashboard';
import Profil from '../pages/Profil';
import Render from '../pages/questionnaire/Render';
import { getUser } from '../service/auth';
import GestionUsers from '../pages/Admin/Gestion User/GestionUsers';
import Error404 from '../pages/Error404';
import Admin from '../pages/Admin/Admin';
import EditUsers from '../pages/Admin/Gestion User/EditUsers';
import AddUser from '../pages/Admin/Gestion User/AddUser';
import AssocQuestionnaire from '../pages/Admin/Gestion User/AssocQuestionnaire';
import ShowUser from '../pages/Admin/Gestion User/ShowUser';
import BilanAdmin from '../pages/Admin/Gestion User/BilanAdmin';
import GestionQuestionnaire from '../pages/Admin/Gestion questionnaire/GestionQuestionnaire';
import AssocUsers from '../pages/Admin/Gestion questionnaire/AssocUsers';
import FormGenerator from './FormGenerator';
import { SnackProvider } from './SnackContext';
import ShowQuestionnaire from '../pages/Admin/Gestion questionnaire/ShowQuestionnaire';
import Formulaire from '../pages/Formulaire';
import AdminRender from '../pages/questionnaire/AdminRender';
import Aide from '../pages/Aide'
import Firewall from './Firewall';
import { Publications } from '../pages/Publications';
import Inscription from '../pages/Inscription';
import Login from '../pages/Login';
import Consentement from '../pages/Consentement';
import { ShowPublications } from '../pages/ShowPublications';
import Forget from '../pages/Forget';
import { ChangeMdp } from '../pages/ChangeMdp';

const RouterAuth = () => {

    const user = getUser()

    return (
        <SnackProvider>
            <Routes>
                <Route path="/" element={<Home />}>
                    <Route path="/inscription" element={<Inscription />} />
                    <Route path="/authentification" element={<Login />} />
                    <Route path="/formulaire" element={<Formulaire />} />
                    <Route path="/forget" element={<Forget />} />
                    <Route path="/change_mdp/:token" element={<ChangeMdp />} />
                    <Route path="/" element={<Publications />} />
                    <Route path="/dashboard" element={<Firewall><Dashboard /></Firewall>} />
                    <Route path="/profil" element={<Firewall><Profil /></Firewall>} />
                    <Route path="/consentement" element={<Firewall><Consentement /></Firewall>} />
                    <Route path='/aide' element={<Aide />} />
                    <Route path='/static/media/*' element={<ShowPublications/>} />
                    <Route path="/q/*" element={<Firewall><Render /></Firewall>}>
                        {/* <Route path="/q/activites_physiques" element={<ApStep1/>} /> */}
                    </Route>
                    {user?.admin ?
                        <Route path="/admin" element={<Firewall><Admin /></Firewall>} >
                            <Route path="/admin/gestion_user" element={<GestionUsers />} />
                            <Route path="/admin/gestion_questionnaire" element={<GestionQuestionnaire />} />
                            <Route path='/admin/user/edit' element={<EditUsers />} />
                            <Route path='/admin/user/add' element={<AddUser />} />
                            <Route path='/admin/assoc_quest' element={<AssocQuestionnaire />} />
                            <Route path='/admin/assoc_users' element={<AssocUsers />} />
                            <Route path='/admin/user/show' element={<ShowUser />} />
                            <Route path="/admin/q/*" element={<BilanAdmin />} />
                            <Route path='/admin/questionnaire/edit' element={<FormGenerator />} />
                            <Route path='/admin/questionnaire/show' element={<ShowQuestionnaire />} />
                            <Route path='/admin/edit/quest' element={<AdminRender />} />

                        </Route>

                        : null
                    }
                    <Route path="*" element={<Error404 />} />
                </Route>
                
                

            </Routes>
        </SnackProvider>
    );
};

export default RouterAuth;
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
  } from "@mui/material";
  import FormInputText from "../../../components/FormInputText";
  import axios from "axios";
  import { getHeader } from "../../../service/auth";
  import { useForm } from "react-hook-form";
  import { useSnackContext } from "../../../components/SnackContext";
import themeNutriFast from "../../../styles/NutriFastColors";
  
  const AddPage = ({openDialog,setOpenDialog,setPage,page,quest_id}) => {
    const defaultValues = { title: "", questionnaire_id: quest_id, page: page };
    const { handleSubmit, control,register } = useForm({ defaultValues });
    const { setAlert } = useSnackContext();
    const headers = getHeader();
  
    const handleClose = () => {
      setOpenDialog(false);
    };
  
    const onSubmit = async (data) => {
      await axios
        .put(process.env.REACT_APP_ADMIN+'/'+quest_id, { data }, { headers })
        .then((response) => {
          setAlert({
            message: "Page ajouté",
            severity: "info",
            open: false,
          });
          setOpenDialog(false);
          setPage(current => [...current,{ id: response.data.id, title: data.title, page:page }]);
        });
    };
  
    return (
      <Dialog open={openDialog} onClose={handleClose}>
        <DialogTitle>Ajout de Page</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)} className="form">
            <section>
              <FormInputText label="Titre" name="title" control={control} />
            </section>
            <input
              type="hidden"
              name="questionnaire_id"
              required={true}
              {...register("questionnaire_id")}
            />
            <input
              type="hidden"
              name="page"
              required={true}
              {...register("page")}
            />
            <DialogActions>
              <button  style={{backgroundColor:themeNutriFast.palette.primary.dark}} className="btn-nutrifast" type="submit">
                Envoyer
              </button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    );
  };
  
  export default AddPage;
  
import * as React from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { useState } from 'react';
import { ListItemButton } from '@mui/material';
import axios from 'axios';
import { getHeader } from '../service/auth';
import { useNavigate } from 'react-router-dom';
import themeNutriFast from '../styles/NutriFastColors';

function not(a, b) {
  let data = a.filter(element => {
    let test = b.filter(el => element.id === el.id) 
    return test.length === 0
  });
  return data
}



function intersection(a, b) {
  
  return a.filter((value) =>{
    let test = b.find(el => el.id === value.id)
      
    
    return test
  });
}

const TransferList =   ({disponible,selected,id,link,retour}) => {
  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState([]);
  const [right, setRight] = useState([]);
  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);
  const headers = getHeader();
  const navigate = useNavigate()

  React.useEffect(() => {
    setLeft(not(disponible,selected))
    setRight(selected)
  },[disponible,selected])

  const handleToggle = (value) => () => {
    
    // const currentIndex = checked.find(check => check.id === value.id);
    const currentIndex = checked.find(el => el.id === value.id);
    const newChecked = [...checked];

    if (currentIndex === undefined) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
    // console.log(leftChecked)
  };

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleCheckedRight = () => {
    
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
  };

  const handleValid = () => {
    axios
      .post(link , { id: id,data:right }, { headers })
      .then(() => navigate('/admin/'+retour) )
      .catch((err) => {
        console.log(err)
      });
    
  }

  const customList = (items) => (
    <>
    <Paper sx={{ width: 200, height: 230, overflow: 'auto' }}>
      <List dense component="div" role="list">
        {items.map((value) => {
          return (
            <ListItemButton
              key={value.id}
              role="listitem"
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.find(el => el.id === value.id) !== undefined}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': value.id,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={value.id} primary={value.name} />
            </ListItemButton>
          );
        })}
      </List>
    </Paper>
    </>
  );

  return (
    <>
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      
      <Grid item>
        <h4>Disponible</h4>
        {customList(left)}</Grid>

      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleAllRight}
            disabled={left.length === 0}
            aria-label="move all right"
          >
            ≫
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleAllLeft}
            disabled={right.length === 0}
            aria-label="move all left"
          >
            ≪
          </Button>
        </Grid>
      </Grid>
      <Grid item>
        <h4>Associé</h4>
        {customList(right)}
      </Grid>
    </Grid>
    <Button  style={{
display: 'block',
width: 'maxContent',
margintop: '20px !important',margin: "auto",backgroundColor:themeNutriFast.palette.primary.dark}} className="btn-nutrifast" title="Valider"  onClick={() => {handleValid()}}> Valider </Button>
    </>
  );
}

export default TransferList;


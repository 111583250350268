import React, { useEffect } from 'react';
import CreateUserForm from '../components/CreateUserForm';
import { useLocation, useNavigate } from 'react-router-dom';

const Inscription = () => {

  let { state } = useLocation();

  let navigate = useNavigate();

  useEffect(() => {
    if (!state) {
      navigate("/formulaire");
    }
  }, []);
    return <CreateUserForm returnLink={'/authentification'} title={ 'Inscription'} sendmail={true} />
};

export default Inscription;
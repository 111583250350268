import React from 'react';
import themeNutriFast from '../styles/NutriFastColors';
import { motion } from 'framer-motion';

const Consentement = () => {
  let style_p = {
    lineHeight: "1",
    marginBottom: "0px",
    marginTop: "0px",
    // textAlign: "center",
    display: "block"
  };
  let style_span = {
    fontFamily: "Calibri",
    fontSize: "16px",
  };
  return (
    <>
      <motion.figure style={{ marginTop: "20px" }}  initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 2 }} >
        <table
        className='consentement-form'

        >
          <tbody>
            <tr>
              <td
            
                style={{
                  border: "1px solid #aca9a9",
                  padding: "20.8px 7.2px",
                  verticalAlign: "top",
                  width: "604.13px",
                  backgroundColor: "rgb(240, 242, 245)",
                  color: themeNutriFast.palette.primary.dark,
                  fontSize: "20px",
                  fontWeight: "bold"
                }}
              >
                <p style={{ style_p }}>
                  <span style={{ style_span }}>
                    « Évaluation nutritionnelle chez les personnes présentant
                    une cirrhose hépatique&nbsp;»&nbsp;
                  </span>
                </p>
                <p style={{ style_p }}>
                  <span style={{ style_span }}>(Nutri-CIRR)</span>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </motion.figure >



      <motion.div   className='wrap_content_consentement'>


        <div>
          <p style={{ textAlign: "center", style_p }}>
            <span style={{ style_span }}>
              <strong>
                Le promoteur de cette recherche est l’Université Sorbonne Paris
                Nord,
              </strong>
            </span>
          </p>
          <p style={{ textAlign: "center", style_p }}>
            <span style={{ style_span }}>
              <strong>
                99 avenue Jean-Baptiste Clément – 93430 Villetaneuse
              </strong>
            </span>
          </p>

          <p style={{ textAlign: "center", borderBottom: "1px solid #aca9a9" }}>
            <span style={{ style_span }}><strong>NOTE D’INFORMATION MAJEUR, V2.0 du 04/07/2024</strong></span>
          </p>
          <p  >
            Madame le Docteur Alice Bellicha, coordonnatrice de l’étude Nutri-CIRR et enseignante-chercheure au sein de l’Équipe de Recherche en Epidémiologie Nutritionnelle (EREN, Université Sorbonne Paris Nord / U1153 Inserm / U1125 Inrae / Cnam), vous propose de participer à une recherche concernant vos habitudes alimentaires et d’activité physique.
          </p>

          <p style={{ style_p }}>
            <span style={{ style_span }}>Cette recherche est soumise aux articles L.1121-1 et suivants du Code de la Santé Publique. L’Université Paris XIII – Sorbonne Paris Nord (99 avenue Jean Baptiste Clément 93430 Villetaneuse), représentée par son président, M. Christophe FOUQUERÉ, est responsable de traitement. La base légale du traitement est l’exécution d’une mission de service public d’enseignement supérieur prévues à l’article L. 123-3 du code de l'éducation. Cette recherche est menée en collaboration avec le service d’Hépatologie de l’hôpital Avicenne Bobigny 93 (Assistance Publique-Hôpitaux de Paris).&nbsp;</span>
          </p>

          <p style={{ style_p }}>
            <span style={{ style_span }}>Il est important de lire attentivement cette note d’information avant de prendre votre décision&nbsp;; n’hésitez pas à nous demander des explications. Si vous décidez d’y participer, un consentement électronique vous sera demandé.</span>
          </p>

          <p style={{ style_p }}>
            <span style={{ style_span }}><strong>1) But de la recherche</strong></span>
          </p>

          <p style={{ style_p }}>
            <span style={{ style_span }}>Le rôle des facteurs nutritionnels a été insuffisamment étudié dans les maladies hépatiques chroniques telles que la cirrhose. À ce jour, la majorité des études s’est concentrée sur le rôle de la dénutrition protéino-énergétique et de la fonte musculaire. La dénutrition étant très fréquente chez les patients atteints de cirrhose, elle constitue donc un facteur aggravant qu’il est important de dépister et de prévenir. La pratique d’activité physique apparaît également comme un facteur protecteur vis-à-vis des complications de la cirrhose, notamment parce qu’elle freine la perte de masse musculaire. Au-delà de la dénutrition et des carences nutritionnelles dont le rôle a été bien étudié, il apparaît au contraire que les autres facteurs nutritionnels, tels que l’adhésion aux recommandations diététiques prenant en compte la consommation de nombreux groupes d’aliments, ont été peu étudiés chez les patients atteints de cirrhose. Il est indispensable de mieux appréhender le rôle de l’alimentation dans sa complexité dans la perspective de formuler des recommandations de santé publique. L’objectif principal de la recherche Nutri-CIRR est d’identifier et caractériser des profils typiques de patients atteints de cirrhose hépatique sur le plan nutritionnel, de la consommation d’alcool et de tabac, et de l’activité physique.</span>
          </p>


          <p >
            <span style={{ style_span }}><strong>2) Aspects méthodologiques</strong></span>
          </p>

          <p style={{ style_p }}>
            <span style={{ style_span }}>Dans la recherche proposée, nous allons évaluer par questionnaire vos habitudes alimentaires et d’activité physique, votre consommation d’alcool et de tabac, et votre situation socioéconomique et démographique actuelle. Ces questionnaires se remplissent entièrement en ligne, sur un site sécurisé.</span>
          </p>

          <p style={{ style_p }}>
            <span style={{ style_span }}><strong>3) Modalités de participation à cette recherche</strong></span>
          </p>

          <p style={{ style_p }}>
            <span style={{ style_span }}>Si vous n’exercez pas d’opposition à cette recherche, vous aurez la possibilité de répondre à quatre questionnaires permettant d’évaluer&nbsp;:&nbsp;</span>
          </p>
          <ul>
            <li>

              <span style={{ style_span }}>Votre consommation habituelle des principaux aliments et groupes d’aliments</span>

            </li>
            <li>

              <span style={{ style_span }}>Votre activité physique au cours des 7 derniers jours</span>

            </li>
            <li>

              <span style={{ style_span }}>Votre situation familiale, liée à l’emploi, et votre mode de vie (consommation d’alcool, tabagisme)</span>

            </li>
            <li>

              <span style={{ style_span }}>Votre poids et votre taille</span>

            </li>
          </ul>

          <p style={{ style_p }}>
            <span style={{ style_span }}>Le remplissage des 4 questionnaires nécessite 1 h au maximum. Après avoir répondu à l’ensemble des questionnaires, votre participation à l’étude prend fin.&nbsp;</span>
          </p>


          <p style={{ style_p }}>
            <span style={{ style_span }}>Une adresse mail générique (etude@nutri-cirr.fr) sera créée afin de vous permettre de contacter les responsables de l’étude, qui pourront alors répondre à vos éventuelles interrogations sur le remplissage des questionnaires.</span>
          </p>


          <p style={{ style_p }}>
            <span style={{ style_span }}><strong>4) Bénéfices et contraintes liés à votre participation</strong></span>
          </p>

          <p style={{ style_p }}>
            <span style={{ style_span }}>Vous ne recevrez aucune indemnité pour votre participation à cette recherche.</span>
          </p>
          <p style={{ style_p }}>
            <span style={{ style_span }}>Grâce à votre participation à ce protocole, vous contribuerez à une meilleure connaissance scientifique dans le domaine de la nutrition et de la santé, et plus particulièrement des liens entre la nutrition et la cirrhose hépatique.</span>
          </p>

          <p style={{ style_p }}>
            <span style={{ style_span }}>A l’issue de la recherche et après analyse des données liées à cette étude, vous pourrez demander à être informé(e) des résultats globaux de l’étude par le Docteur Alice Bellicha. Les résultats globaux de cette étude pourront être présentés à des congrès nationaux ou internationaux et feront l’objet de publications scientifiques. Aucun résultat individuel ne sera communiqué aux participants.</span>
          </p>

          <p style={{ style_p }}>
            <span style={{ style_span }}>Les contraintes liées à cette recherche sont celles liées au temps nécessaire (1h maximum) pour répondre aux questionnaires.&nbsp;</span>
          </p>

          <p style={{ style_p }}>
            <span style={{ style_span }}>Votre participation à l’étude Nutri-CIRR ne modifiera en rien les modalités de suivi prévus dans votre centre de soins.&nbsp;</span>
          </p>

          <p>
            <span style={{ style_span }}><strong>5) Traitement des données recueillies dans le cadre de ce projet de recherche et droits associés</strong></span>
          </p>

          <p style={{ style_p }}>
            <span style={{ style_span }}>L’ensemble des données collectées dans le cadre de cette recherche sera stocké sur les serveurs de l’EREN via des procédures sécurisées, où elles seront conservées pendant 2 ans après la dernière publication.&nbsp;</span>
          </p>

          <p style={{ style_p }}>
            <span style={{ style_span }}>Dans le cas où vous avez participé au projet FASTRAK « <i>Etude randomisée évaluant l’impact sur les coûts et l’efficacité de la surveillance par Fast-IRM hépatique systématique pour la détection du carcinome hépatocellulaire au stade précoce chez les patients à haut risque inclus dans des programmes de surveillance par échographie</i> » (AP-HP), les données pseudonymisées pourront être partagées avec l’équipe coordinatrice du projet sous la responsabilité du Pr Pierre Nahon (Hépatologue CHU Avicennes APHP) dans le respect des règles de gestion des données informatiques. Les données </span><span >pourront être utilisées ultérieurement dans le cadre de futurs projets de recherche en collaboration avec des partenaires publics, en France ou à l’étranger, dans des conditions assurant leur confidentialité et le même niveau de protection que la législation européenne.</span>
          </p>

          <p style={{ style_p }}>
            <span style={{ style_span }}>Afin d’être informé de l’utilisation de vos données pour de futurs projets de recherche, vous pouvez vous </span><span style={{ style_span }}>connecter à </span><a target="_blank" rel="noopener noreferrer" href="https://etude-nutri-cirr.fr/"><span style={{ style_span }}><span >https://etude-nutri-cirr.fr</span></span></a>
          </p>

          <p style={{ style_p }}>
            <span style={{ style_span }}>Les données vous concernant utilisées dans le cadre de cette recherche seront traitées sous forme codée et de façon confidentielle, et conformément aux dispositions du Règlement (UE) 2016/679 du Parlement européen et du Conseil du 27 avril 2016 (RGPD) et de la loi n° 2018493 du 20 juin 2018 relative à la protection des données personnelles. Les conditions de sécurité seront assurées pour le transfert, le stockage et l’exploitation des données.</span>
          </p>

          <p style={{ style_p }}>
            <span style={{ style_span }}>Les dispositions de ces mêmes textes vous garantissent un droit d’information, d’accès et de rectification de vos données ainsi qu’un droit à la limitation de traitement et à la suppression de vos données. Les données recueillies sont utilisées exclusivement dans le cadre des objectifs de la recherche.&nbsp;</span>
          </p>

          <p style={{ style_p }}>
            <span style={{ style_span }}>Vous disposez également d’un droit d’opposition à la transmission des données couvertes par le secret professionnel susceptibles d’être utilisées et traitées dans le cadre de cette recherche. A noter que ce refus empêche du même coup votre participation à cette recherche.</span>
          </p>

          <p style={{ style_p }}>
            <span style={{ style_span }}>En cas de difficultés dans l’exercice de vos droits, vous pouvez saisir le Délégué à la Protection des données de l’USPN à l’adresse suivante&nbsp;: </span><a href="mailto:dpo@univ-paris13.fr"><span ><u>dpo@univ-paris13.fr</u></span></a><span style={{ style_span }}>, qui pourra notamment vous expliquer les voies de recours dont vous disposez auprès de la CNIL.</span><span > Vous pouvez également exercer votre droit à réclamation directement auprès de la CNIL (pour plus d’informations à ce sujet, rendez-vous sur le site </span><a target="_blank" rel="noopener noreferrer" href="http://www.cnil.fr/"><span ><u>www.cnil.fr</u></span></a><span >).</span>
          </p>

          <p style={{ style_p }}>
            <span style={{ style_span }}><strong>7) Autres aspects légaux</strong></span>
          </p>

          <p style={{ style_p }}>
            <span style={{ style_span }}>L’équipe de l’étude Nutri-CIRR (EREN) a obtenu l’avis favorable du Comité de Protection des Personnes (Sud-Méditerranée I, avis n°</span> <span style={{ style_span }}>23.03590.000254-MS01) pour cette recherche le 10/07/2024. Cette recherche se conforme également à la méthodologie de référence MR003 de la Commission Nationale de l'Informatique et des Libertés.</span>
          </p>

          <p style={{ style_p }}>
            <span style={{ style_span }}>Le traitement de vos données à caractère personnel est encadré par le Règlement (UE) 2016/679 du Parlement européen et du Conseil du 27 avril 2016 (RGPD).</span>
          </p>

          <p style={{ style_p }}>
            <span style={{ style_span }}>Vous pourrez tout au long de la recherche demander des explications sur son déroulement au Docteur Alice Bellicha. </span><span >A l’issue de la recherche et après analyse des données relatives à cette recherche, vous pourrez être informé(e) des résultats globaux par l’intermédiaire du </span><span style={{ style_span }}>Docteur Alice Bellicha</span><span>.</span>
          </p>

          <p style={{ style_p }}>
            <span style={{ style_span }}>Votre participation à cette recherche est entièrement libre. Vous pouvez décider à tout moment d’interrompre votre participation sans avoir à vous justifier, sans encourir aucune responsabilité ni aucun préjudice. Pour cela il vous suffit de revenir sur votre consentement en contactant les coordinateurs de l’étude ou en vous connectant sur votre espace personnel sur le site internet de l’étude. Dans ce cas, les données collectées jusqu’au retrait seront utilisées pour l’analyse des résultats de la recherche.</span>
          </p>

          <p style={{ style_p }}>
            <span style={{ style_span }}>Après avoir lu toutes ces informations et après avoir bénéficié d’un temps de réflexion, si vous acceptez de participer à la recherche vous devrez signer et dater le formulaire de consentement éclairé se trouvant à la fin de ce document.</span>
          </p>

          <p style={{ textAlign: "center", borderBottom: "1px solid #aca9a9" }}>
            <span style={{ style_span }}>
              <strong>FORMULAIRE DE NON OPPOSITION</strong>
            </span>
          </p>
        </div>


        <p style={{style_p}}>
    <span style={{style_span}}>J’accepte librement de participer à la recherche intitulée « Évaluation nutritionnelle chez les personnes présentant une cirrhose hépatique&nbsp;» organisée par l’Université Sorbonne Paris Nord et qui m’est proposée par le Docteur Alice Bellicha, membre de l’Équipe de Recherche en Épidémiologie Nutritionnelle (EREN) U1153 Inserm / U1125 Inra / Cnam / Université Sorbonne Paris Nord.</span>
</p>
<p style={{style_p}}>
    &nbsp;
</p>
<p style={{style_p}}>
    <span style={{style_span}}>J’aurai accès et je pourrai consulter à tout moment la lettre d’information et mon formulaire de non opposition dans mon espace personnel Nutri-CIRR (rubrique « Mon formulaire »).</span>
</p>
<p style={{style_p}}>
    &nbsp;
</p>
<ul>
    <li>
        <p style={{style_p}}>
            <span style={{style_span}}>J’ai pris connaissance de la note d’information version 2.0 du 04/07/2024 (4 pages) m’expliquant l’objectif de cette recherche, la façon dont elle va être réalisée et ce que ma participation va impliquer.</span>
        </p>
    </li>
    <li>
        <p style={{style_p}}>
            <span style={{style_span}}>J’ai bien noté que je peux contacter l’EREN en adressant un courriel à l’adresse générique etude@nutri-cirr.fr pour obtenir des renseignements sur l’étude et sur le remplissage des questionnaires.&nbsp;</span>
        </p>
    </li>
    <li>
        <p style={{style_p}}>
            <span style={{style_span}}>J’accepte que les données relatives à mon mode de vie enregistrées sous forme codée à l’occasion de cette recherche puissent faire l'objet d’un traitement informatisé par le promoteur de l’étude et être réutilisées ultérieurement dans les conditions précisées dans la lettre d’information.</span>
        </p>
    </li>
    <li>
        <p style={{style_p}}>
            <span style={{style_span}}>Les données recueillies demeureront strictement confidentielles. Je n’autorise leur consultation que par les personnes dûment mandatées par les coordonnateurs de l’étude Nutri-CIRR.</span>
        </p>
    </li>
    <li>
        <p style={{style_p}}>
            <span style={{style_span}}>J'ai bien noté que je pourrai exercer à tout moment auprès de l’investigateur coordinateur de la recherche mes différents droits (accès, rectification…) prévu par le Règlement (UE) 2016/679 du Parlement européen et du Conseil du 27 avril 2016 (RGPD) et la loi n° 2018-493 du 20 juin 2018 relative à la protection des données personnelles.</span>
        </p>
    </li>
    <li>
        <p style={{style_p}}>
            <span style={{style_span}}>Ma non-opposition ne décharge en rien les investigateurs de l’ensemble de leurs responsabilités et je conserve tous mes droits garantis par la loi.</span>
        </p>
    </li>
    <li>
        <p style={{style_p}}>
            <span style={{style_span}}>Je suis parfaitement conscient(e) que je pourrai interrompre ma participation à cette recherche à tout moment, quelles que soient mes raisons, sans avoir à m’en justifier et sans encourir la moindre responsabilité et préjudice.</span>
        </p>
    </li>
    <li>
        <p style={{style_p}}>
            <span style={{style_span}}>Aucune indemnité n’est prévue et je ne tirerai aucun éventuel avantage financier pour ma participation à cette recherche. Toutefois ma participation contribuera au progrès scientifique.</span>
        </p>
    </li>
    <li>
        <p style={{style_p}}>
            <span style={{style_span}}>J’ai bien compris que je suis libre d’accepter ou de refuser de participer à cette recherche.</span>
        </p>
    </li>
</ul>
      </motion.div>
    </>
  );
};

export default Consentement;
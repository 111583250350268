
import { Outlet } from 'react-router-dom'
import { Box, CssBaseline, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemText, Toolbar, Typography } from "@mui/material";
import { Menu, ChevronLeft, ChevronRight, DynamicFormOutlined, HelpCenterOutlined, Login, AppRegistration, FeedOutlined } from "@mui/icons-material";
import { AppBar, DrawerHeader, Main, drawerWidth } from "../components/Menu";
import { useState } from 'react';
import { useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import themeNutriFast from "./../styles/NutriFastColors";
import { getUser, isLogged, logout } from '../service/auth';
import Logout from "@mui/icons-material/Logout";
import AccountCircleOutlined from "@mui/icons-material/AccountCircleRounded";
import EditNoteRounded from "@mui/icons-material/EditNoteRounded";
import ManageAccountsRounded from "@mui/icons-material/ManageAccountsRounded";
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import LogosFooter from '../components/LogosFouter';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useEffect } from 'react';
const Home = () => {

  const user = getUser()
  const theme = useTheme();
  const [open, setOpen] = useState(true);


  let navigate = useNavigate();
  const handleChangePage = (page) => {
    if (page)
      navigate(page)
  }
  const isMobile = useMediaQuery('screen and (min-device-width : 320px) and (max-device-width : 480px)');
  const isTablette = useMediaQuery('screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait)');


useEffect(() => {
  if (isMobile || isTablette) {
    setOpen(false);
  }else{
    setOpen(true);
  }

}, [isMobile, isTablette])


  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    logout();
    navigate('/authentification', { state: { loggedOut: true, message: 'Vous avez bien été déconnecté' } });
  }


  return (
    <Box sx={{ display: 'flex' 
      
    }} >
      <CssBaseline />
      <AppBar position="fixed" open={open} theme={theme}
        style={{ backgroundColor: themeNutriFast.palette.primary.light, color: themeNutriFast.palette.primary.dark }}

      >
        <Toolbar  >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          
          >
            <Menu />
          </IconButton>
          <Typography variant="h6" noWrap component="div" style={{cursor:"pointer"}} onClick={() => handleChangePage('/')}
          >
   
           Nutri-CIRR
          </Typography>
          {isLogged() ?  <Logout  titleAccess="Se deconnecter " onClick={handleLogout} className='logout-icon' > </Logout> :    <LockOpenIcon  titleAccess="S'identifier" onClick={() => handleChangePage('/authentification')} className='logout-icon'></LockOpenIcon>}
        </Toolbar>
      </AppBar>
      <Drawer
    
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          open: { xs: true, sm: false },
          '& .MuiDrawer-paper': {
            backgroundColor: themeNutriFast.palette.primary.dark,
            color: themeNutriFast.palette.primary.contrastText,
            borderRight: "red",
            width: drawerWidth,
            boxSizing: 'border-box',
          },
          
        }}
        variant="persistent"
        anchor="left"
        
        open={open}
        ModalProps={{
          keepMounted: false,
        }}


      >
        <DrawerHeader theme={theme} >

          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeft style={{ color: themeNutriFast.palette.primary.contrastText }} /> : <ChevronRight />}
          </IconButton>
        </DrawerHeader>
        <Divider style={{ backgroundColor: themeNutriFast.palette.primary.contrastText }} />
        <List>
          {isLogged() ? (<>
            <ListItem key='dashboard' disablePadding>
              <ListItemButton onClick={() => handleChangePage('/dashboard')}>
                <QuestionAnswerOutlinedIcon className='link' />
                <ListItemText sx={{ marginLeft: 1 }} primary='Questionnaires' />
              </ListItemButton>
            </ListItem>
            <Divider />
            <ListItem key='profil' disablePadding>
              <ListItemButton onClick={() => handleChangePage('/profil')}>
                <AccountCircleOutlined className='link' />
                <ListItemText sx={{ marginLeft: 1 }} primary='Profil' />
              </ListItemButton>
            </ListItem>
            <ListItem key='publications' disablePadding>
            <ListItemButton onClick={() => handleChangePage('/')}>
              <FeedOutlined className='link' />
              <ListItemText sx={{ marginLeft: 1 }} primary='Informations' />
            </ListItemButton>
          </ListItem>
          <Divider />
          {user?.admin ? (<>
            <Divider />
            <ListItem key='admin_users' disablePadding>
              <ListItemButton onClick={() => handleChangePage('/admin/gestion_user')}>
                <ManageAccountsRounded className='link' />
                <ListItemText sx={{ marginLeft: 1 }} primary='Gestion des utilisateurs' />
              </ListItemButton>
            </ListItem>
            <Divider />
            <ListItem key='admin_quest' disablePadding>
              <ListItemButton onClick={() => handleChangePage('/admin/gestion_questionnaire')}>
                <EditNoteRounded className='link' />
                <ListItemText sx={{ marginLeft: 1 }} primary='Gestion des questionnaires' />
              </ListItemButton>
            </ListItem>
            <Divider />
          </>) : null}
          <ListItem key='aide' disablePadding>
            <ListItemButton onClick={() => handleChangePage('/aide')}>
              <HelpCenterOutlined className='link' />
              <ListItemText sx={{ marginLeft: 1 }} primary='Aide' />
            </ListItemButton>
          </ListItem>
          <Divider />
            <ListItem key='formulaire' disablePadding>
              <ListItemButton onClick={() => handleChangePage('/consentement')}>
                <DynamicFormOutlined className='link' />
                <ListItemText sx={{ marginLeft: 1 }} primary='Mon consentement' />
              </ListItemButton>
            </ListItem>
            <Divider />
            <ListItem key='logout' disablePadding>
              <ListItemButton onClick={handleLogout}>
                <Logout className='link' />
                <ListItemText sx={{ marginLeft: 1 }} primary='Déconnexion' />
              </ListItemButton>
            </ListItem>
          </>) :
            (<>
              <ListItem key='inscription' disablePadding>
                <ListItemButton onClick={() => handleChangePage('/inscription')}>
                  <AppRegistration className='link' />
                  <ListItemText sx={{ marginLeft: 1 }} primary="Je m'inscris" />
                </ListItemButton>
              </ListItem>
              <Divider />
              <ListItem key='connexion' disablePadding>
                <ListItemButton onClick={() => handleChangePage('/authentification')}>
                  <Login className='link' />
                  <ListItemText sx={{ marginLeft: 1 }} primary='Je me connecte' />
                </ListItemButton>
              </ListItem>
              <Divider />

            </>)

          }
          
          
        </List>
      </Drawer>
      <Main theme={theme} open={open}  >
        <DrawerHeader theme={theme} />

        <Outlet />
        <LogosFooter />
      </Main>
    </Box>

  )
}

export default Home;
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
  } from "@mui/material";
  import FormInputText from "../../../components/FormInputText";
  import axios from "axios";
  import { getHeader } from "../../../service/auth";
  import { useForm } from "react-hook-form";
  import { useSnackContext } from "../../../components/SnackContext";
import { useEffect } from "react";
import themeNutriFast from "../../../styles/NutriFastColors";
  
  const EditQuestionnaire = ({openDialog,setOpenDialog,setQuestionnaires,defaultValues}) => {
    // const defaultValues = { name: "", table_result: "" };
    const { handleSubmit, control, register,setValue } = useForm({ defaultValues });
    const { setAlert } = useSnackContext();
    const headers = getHeader();
  
    const handleClose = () => {
      setOpenDialog(false);
    };

    useEffect(() => {
      setValue('name',defaultValues.name)
      setValue('questionnaire_id',defaultValues.questionnaire_id)
    })
  
    const onSubmit = async (data) => {
      // console.log(data)
      await axios
        .post(process.env.REACT_APP_ADMIN+'/quest_name', { id:data.questionnaire_id, name: data.name }, { headers })
        .then((response) => {
          setAlert({
            message: "Nom modifié",
            severity: "info",
            open: false,
          });
          setOpenDialog(false);
          setQuestionnaires(response.data);
        });
    };
  
    return (
      <Dialog open={openDialog} onClose={handleClose}>
        <DialogTitle>Edition de questionnaire</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)} className="form">
            <section>
              <FormInputText label="Nom" name="name" control={control} />
            </section>

            <input
              type="hidden"
              name="questionnaire_id"
              required={true}
              {...register("questionnaire_id")}
            />
            <DialogActions>
              <button  style={{backgroundColor:themeNutriFast.palette.primary.dark}} className="btn-nutrifast" type="submit">
                Envoyer
              </button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    );
  };
  
  export default EditQuestionnaire;
  
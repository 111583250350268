import React from 'react';
import ProfilForm from '../../../components/ProfilForm';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';

const EditUsers = () => {
    const  {state} = useLocation()
    let date = dayjs(state.birth_date)
    state.birth_date = date
    return <ProfilForm defaultValues={state} admin= {true}  />
    // return <div>Edit</div>
};

export default EditUsers;
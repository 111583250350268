import { Grid } from '@mui/material';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import React from 'react';

const InfoAlert = ({ message, onClose }) => {

    return (
        <Grid sx={{ paddingBottom: 1 }} item xs={12}>
            <Alert onClose={onClose} severity="info">
                <AlertTitle>{message && message}</AlertTitle>
            </Alert>
        </Grid>
    )
}

export default InfoAlert;

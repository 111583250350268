import axios from "axios";
import React, { useEffect, useState } from "react";
import { getHeader } from "../../../service/auth";
import { useLocation } from "react-router-dom";
import TransferList from "../../../components/TransfertList";
import SnackAlert from "../../../components/SnackAlert";

const AssocUsers = () => {
  const headers = getHeader();
  const { state } = useLocation();
  const [disponible, setDisponible] = useState([]);
  const [selected, setSelected] = useState([]);
  const [alert, setAlert] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlert(false);
  };

  useEffect(() => {
    axios
    .get(process.env.REACT_APP_ADMIN+'/user/quest/' + state, { headers })
    .then(({data}) => {
        
        setSelected(data);
    })
    .catch((err) => {
        setAlert(true);
    });

    axios
      .get(process.env.REACT_APP_ADMIN , { headers })
      .then(({data}) => {
        setDisponible(data);
      })
      .catch((err) => {
        setAlert(true);
      });
    
  }, []);

  return (
    <>
      <TransferList disponible={disponible} selected={selected} id ={state} link={process.env.REACT_APP_ADMIN+"/quest/assoc"} retour={'gestion_questionnaire'} />
      <SnackAlert
        open={alert}
        severity="error"
        message="Une erreur s'est produite"
        handleClose={handleClose}
      />
    </>
  );
};

export default AssocUsers;

import axios from "axios";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import FormDatePicker from "./FormDatePicker";
import { Alert, AlertTitle, Grid } from "@mui/material";
import dayjs from "dayjs";

import themeNutriFast from "../styles/NutriFastColors";
import { FormInputRadio } from "./FormInputRadio";

const major = dayjs().subtract(16, "year");

const CreateUserForm = ({ returnLink, title }) => {
  // const [errorMessages, setErrorMessages] = useState({});
  // const [error, setError] = useState(false);
  const defaultValues = {
    username: "",
    // mail: "",
    sexe: "",
    date: "",
    password: "",
    confirm_password: "",
    code1: "",
    code2: "",
    code3: "",
    // telephone: "",
    // sendmail: sendmail,
  };

  let navigate = useNavigate();

  const onSubmit = (data) => {
    let pass = true;

    if (data.confirm_password !== data.password) {
      pass = false;
      setError("password", {
        type: "custom",
        message: "Les mots de passe ne sont pas identiques",
      });
    }
    // if (data.confirm_password !== data.password) {
    //   pass = false;
    //   setError("mail", {
    //     type: "custom",
    //     message: "Les adresses mails ne sont pas identiques",
    //   });
    // }

    if (pass) {
      axios
        .put(process.env.REACT_APP_USER_URL, data)
        .then(() =>
          navigate(returnLink, {
            state: {
              loggedOut: true,
              message:
                "Votre inscription est validée. Vous pouvez maintenant vous connecter",
            },
          })
        )
        .catch(({ response }) => {
          
          setError("root.serverError", {
            type: "500",
            message: response.data.message,
          });
        });
    }
  };

  const {
    control,
    setError,
    formState: { errors },
    register,
    handleSubmit,
  } = useForm({
    defaultValues: defaultValues,
  });

  let buttons = [
    { value: true, label: "Un homme" },
    { value: false, label: "Une femme" },
  ];

  return (
    <div className="Auth-form-container">
      <form className="Auth-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="Auth-form-content">
          <h3 className="Auth-form-title">{title}</h3>
          <div className="form-group mt-3">
            <label>Choisissez votre pseudo</label>
            <input
              type="text"
              className="form-control mt-1"
              aria-invalid={errors.username ? "true" : "false"}
              {...register("username", { required: true })}
            />
            {errors.username?.type === "required" && (
              <p className="text-error">Ce champ est obligatoire</p>
            )}
          </div>
          <FormInputRadio
            label="Êtes-vous :"
            name="sexe"
            buttons={buttons}
            control={control}
            rules={{ required: true }}
            xs={12}
          />
          {errors.sexe?.type === "required" && (
              <p className="text-error">Ce champ est obligatoire</p>
            )}
          {/* <div className="form-group mt-3">
            <label>Votre E-mail</label>
            <input
              type="email"
              className="form-control mt-1"
              name="mail"
              {...register("mail", { required: true })}
            />
            {errors.mail?.type === "required" && (
              <p className="text-error">Ce champ est obligatoire</p>
            )}
          </div> */}
          {/* <div className="form-group mt-3">
            <label>Confirmez votre Mail</label>
            <input
              type="email"
              className="form-control mt-1"
              name="confirm_mail"
              {...register("confirm_mail", { required: true })}
            />
            {errors.confirm_mail?.type === "required" && (
              <p className="text-error">Ce champ est obligatoire</p>
            )}
            {errors.mail?.type === "custom" && (
              <p className="text-error">{errors.mail.message}</p>
            )}
          </div> */}
          <div className="form-group mt-3 container">
            <Grid container spacing={2}>
              <Grid size={12}>
                <label>Votre identifiant Fastrak</label>
              </Grid>
              <Grid size={4}>
                <input
                  type="number"
                  className="form-control mt-1"
                  name="code1"
                  {...register("code1", {
                    required: true,
                    pattern: /^\d{3}$/,
                  })}
                />
              </Grid>
              <Grid size={4}>
                <input
                  type="number"
                  className="form-control mt-1"
                  name="code2"
                  {...register("code2", {
                    required: true,
                    pattern: /^\d{4}$/,
                  })}
                />
              </Grid>
              <Grid size={4}>
                <input
                  type="text"
                  className="form-control mt-1"
                  name="code3"
                  {...register("code3", {
                    required: true,
                    pattern: /^\w{2}$/,
                  })}
                />
              </Grid>
              <Grid size={12}>
                {(errors.code3?.type === "required" ||
                  errors.code2?.type === "required" ||
                  errors.code3?.type === "required") && (
                  <p className="text-error">Ce champ est obligatoire</p>
                )}
                {(errors.code1?.type === "pattern" ||
                  errors.code2?.type === "pattern" ||
                  errors.code3?.type === "pattern") && (
                  <p className="text-error">
                    Votre code n'est pas valide. Si vous ne connaissez pas votre
                    identifiant FASTRAK, faites-en la demande au centre qui
                    assure votre suivi
                  </p>
                )}
              </Grid>
            </Grid>
          </div>
          <div className="form-group mt-3">
            <FormDatePicker
              className="form-control mt-1"
              name="date"
              views={["month", "year"]}
              label={"Date de naissance"}
              control={control}
              maxDate={major}
              rules={{
                required: true,
              }}
              //{...register("date", { required: true })}
            />
            {errors.date?.type === "required" && (
              <p className="text-error">Ce champ est obligatoire</p>
            )}
          </div>
          <div className="form-group mt-3">
            <label>Mot de passe</label>
            <input
              type="password"
              className="form-control mt-1"
              name="password"
              {...register("password", { required: true })}
            />
            {errors.mail?.type === "required" && (
              <p className="text-error">Ce champ est obligatoire</p>
            )}
          </div>
          <div className="form-group mt-3">
            <label>Confirmer votre mot de passe</label>
            <input
              type="password"
              className="form-control mt-1"
              name="confirm_password"
              {...register("confirm_password", { required: true })}
            />
            {errors.password?.type === "required" && (
              <p className="text-error">Ce champ est obligatoire</p>
            )}
            {errors.password?.type === "custom" && (
              <p className="text-error">{errors.password.message}</p>
            )}
          </div>
          {errors?.root?.serverError.type === "500" && (
            <Alert severity="error">
              <AlertTitle>{errors?.root?.serverError.message}</AlertTitle>
            </Alert>
          )}
          <div className="d-grid gap-2 mt-3">
            <button
              type="submit"
              style={{ backgroundColor: themeNutriFast.palette.primary.dark }}
              className="btn-nutrifast"
            >
              Envoyer
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateUserForm;

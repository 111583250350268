import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Consentement from "./Consentement";

const Formulaire = () => {
  const defaultValues = {
    consentement: false,
  };

  let navigate = useNavigate();

  const onSubmit = (data) => {
    navigate("/inscription", { state: data.consentement });
  };

  const { register, handleSubmit } = useForm({
    defaultValues: defaultValues,
  });

  return (
    <>

        <form  onSubmit={handleSubmit(onSubmit)}>
          <div >
            <Consentement />
            <div className="consentement-confirmation" >
            <input 
              type="checkbox"
              name="consentement"
              required={true}
              {...register("consentement", { required: true })}
            />
            <label htmlFor="consentement"> 
               En cochant cette case, j'accepte toutes les affirmations
              ci-dessus.
            </label>
            </div>
            <div className="d-grid gap-2 mt-3">
              <button type="submit" className="btn-nutrifast" style={{margin: "auto", width: "30%", backgroundColor: "rgb(2, 62, 138)"}}>
                Suivant
              </button>
            </div>
          </div>
        </form>
    
    </>
  );
};

export default Formulaire;

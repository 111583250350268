import { Alert, AlertTitle, Box } from "@mui/material";
import axios from "axios"
import React, { useEffect, useState } from "react"
import { isLogged, saveToken } from "../service/auth";
import { useLocation, useNavigate } from "react-router-dom";
import InfoAlert from "../components/InfoAlert";
import themeNutriFast from "../styles/NutriFastColors";


export default function Login (props) {
  const [credentials, setCredentials] = useState({
    mail: '',
    password: ''
  })
  const [errorMessages, setErrorMessages] = useState({});
  const [error,setError] = useState(false);
  const [showMessage, setShowMessage] = useState(true)
  let { state } = useLocation();

  
  let navigate = useNavigate()

  useEffect(() => {
    if (isLogged()){
      navigate('/dashboard')
    }
      
  }, [])

  // const renderErrorMessage = (name) =>
  //   name === errorMessages.name && (
  //   <div className="error">{errorMessages.message}</div>
  // );

  const onChange = (e) => {
    // console.log(e)
    setCredentials({
      ...credentials,
      [e.target.name]: e.target.value
    })
  }
  const onSubmit = async (e) => {
    e.preventDefault()
    await axios.post(process.env.REACT_APP_LOGIN_URL,{credentials})
    // await axios.post('http://localhost:8989/auth/login',{credentials})
    .then( ({data}) => {
      saveToken(data.acces_token)
      navigate('/dashboard')
    })
    .catch(err =>{
       setError(true);
       setErrorMessages('Mot de passe ou identifiant erroné');
    })
  }

  const handleCloseInfoAlert = () => {
    setShowMessage(false)
}


  return (
    <Box>
      { state && state.loggedOut && showMessage &&     
      <InfoAlert message={state.message} onClose={handleCloseInfoAlert}/>}
    

      
    <div className="Auth-form-container">      
      <form className="Auth-form" onSubmit={onSubmit}>
        <div className="Auth-form-content">
          <h3 className="Auth-form-title">Connexion</h3>
          <div className="form-group mt-3">
            <label>Pseudo</label>
            <input
              type="text"
              className="form-control mt-1"
              placeholder="Saississez votre pseudo"
              name="username"
              onChange={onChange}
              required={true}
            />
          </div>
          <div className="form-group mt-3">
            <label>Mot de passe</label>
            <input
              type="password"
              className="form-control mt-1"
              placeholder="Saisissez le mot de passe"
              name="password"
              onChange={onChange}
              required={true}
            />
          </div>
          {error && 
          <Alert severity="error">
                    <AlertTitle>{errorMessages}</AlertTitle>
                    
                </Alert> }
          <div className="d-grid gap-2 mt-3">
            <button type="submit" style={{backgroundColor:themeNutriFast.palette.primary.dark}} className="btn btn-nutrifast" >
              Envoyer
            </button>
          </div>
          <p className="forgot-password text-right mt-2">
             <a href="/forget">Mot de passe oublié</a>
          </p>
          <p className="forgot-password text-right mt-2">
             <a href="/inscription">Je m'inscris</a>
          </p>
        </div>
      </form>
    </div>
    </Box>
  )
}
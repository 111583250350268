import "bootstrap/dist/css/bootstrap.min.css"
import './App.css';
import '../src/styles/new_layout.css'
import { useEffect } from 'react';
import { Route, Routes, useLocation} from "react-router-dom";
import RouterAuth from "./components/RouterAuth";
import { isLogged, logout } from "./service/auth";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import 'dayjs/locale/fr';


function App() {
  
  const location = useLocation();

  useEffect(() => {
    if (!isLogged()){
      logout()
    }
      
  }, [location])
  

  // if(!token) {
  //   return <Login />
  // }
  return (
    <>
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='fr'>
        
          
        
          <Routes>
              <Route path="/*" element={<RouterAuth/>}/>
            
          </Routes>

    </LocalizationProvider>
    </>
      )
}

export default App;

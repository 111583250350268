import {
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { getHeader } from "../service/auth";
import { useNavigate } from "react-router-dom";
import QuestList from "../components/QuestList";
import { motion } from 'framer-motion';
import themeNutriFast from "../styles/NutriFastColors";
export default function Dashboard() {
  const headers = getHeader();
  const [list, setList] = useState([]);
  let navigate = useNavigate();

  const handleChangePage = (page, quest) => {
    if (page) navigate(page, { state: quest });
  };

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_QUESTIONNAIRE, { headers })
      .then(({ data }) => {
        setList(data);
      });
    // axios.get(process.env.REACT_APP_QUESTIONNAIRE,{ headers })
  }, []);

  return (

    <motion.div initial={{opacity:0}} animate={{opacity:1}} transition={{ duration: 2 }}>
      <h2  style={{color:themeNutriFast.palette.primary.dark}}  className='title-dashbord'> Questionnaires </h2>
      { list.newTab && list.newTab.length > 0 && (
        <>
          <Typography variant="h6" gutterBottom className="item-title-dashbord">
            
            Nouveau
          </Typography >
          <QuestList list={list.newTab} handleChangePage={handleChangePage} />
        </>
      )}
      {list.en_cours && list.en_cours.length > 0 && (
        <>
          <Typography variant="h6" gutterBottom className="item-title-dashbord">
            
            En cours
          </Typography>
          <QuestList list={list.en_cours} handleChangePage={handleChangePage} />
        </>
      )}
      {list.valide && list.valide.length > 0 && (
        <>
          <Typography variant="h6" gutterBottom className="item-title-dashbord">
            
            Validé
          </Typography>
          <QuestList list={list.valide} handleChangePage={handleChangePage} />
        </>
      )}
         </motion.div>
  
 
  );
}
